import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { TranslateService } from "@ngx-translate/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";


@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    msg: any = null;
    lang = "en";
    Home: any;
    Signup: any;
    CreateyourAccount: any;
    required: any;
    youmustentervalidemail: any;
    FirstName: any;
    LastName: any;
    Email: any;
    Password: any;
    passwordlenghtmustbe6atleast: any;
    Alreadyaregistereduser: any;
    SignupNow: any;
    Login: any;
    SignStudentNow: any;
    SignInstructorNow: any;

    registerForm = new FormGroup({
        firstName: new FormControl("", [
            Validators.required,
            Validators.minLength(3),
        ]),
        lastName: new FormControl("", [
            Validators.required,
            Validators.minLength(3),
        ]),
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [
            Validators.required,
            Validators.minLength(6),
        ]),
        phone: new FormControl("", [
            Validators.required,
           
        ]),
    });
     iti : any
     currentDialCode :any = '+971';
    constructor(
        private router: Router,
        private _instructor: InstructorService,
        private _student: StudentService,
        public translate: TranslateService,
        private _defult: DefaultService,
        private auth: AuthenticationService
    ) {}
   
    onPhoneNumberChange(event: any) {
        console.log(event.internationalNumber);
        console.log(event.dialCode);
        console.log(event.countryCode);
      }
    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        var input = document.querySelector("#phone");
 this.iti=(window as any).intlTelInput(input, {
          // allowDropdown: false,
          // autoInsertDialCode: true,
           autoPlaceholder: "on",
          // containerClass: "test",
          // countrySearch: false,
          // customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
          //   return "e.g. " + selectedCountryPlaceholder;
          // },
          // defaultToFirstCountry: false,
          // dropdownContainer: document.querySelector('#custom-container'),
          // excludeCountries: ["us"],
           fixDropdownWidth: true,
          // formatAsYouType: false,
          formatOnDisplay: true,
          // geoIpLookup: function(callback) {
          //   fetch("https://ipapi.co/json")
          //     .then(function(res) { return res.json(); })
          //     .then(function(data) { callback(data.country_code); })
          //     .catch(function() { callback(); });
          // },
          // hiddenInput: () => "phone_full",
          // i18n: { 'de': 'Deutschland' },
          initialCountry: "ae",
           nationalMode: true,
          // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
          // placeholderNumberType: "MOBILE",
          // preferredCountries: ['cn', 'jp'],
          // showFlags: false,
          showSelectedDialCode: true,
          // useFullscreenPopup: true,
          utilsScript: "build/js/utils.js"
        });
        input.addEventListener("countrychange", ()=> {
         console.log( this.iti.getSelectedCountryData().dialCode)
         this.currentDialCode = this.iti.getSelectedCountryData().dialCode
          });
       
        console.log("PHONE", input)
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Signup",
                "CreateyourAccount",
                "required",
                "youmustentervalidemail",
                "FirstName",
                "LastName",
                "Email",
                "Password",
                "passwordlenghtmustbe6atleast",
                "Alreadyaregistereduser",
                "SignupNow",
                "Login",
                "SignStudentNow",
                "SignInstructorNow",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Signup = res.Signup;
                this.CreateyourAccount = res.CreateyourAccount;
                this.required = res.required;
                this.youmustentervalidemail = res.youmustentervalidemail;
                this.FirstName = res.FirstName;
                this.LastName = res.LastName;
                this.Email = res.Email;
                this.Password = res.Password;
                this.passwordlenghtmustbe6atleast =
                    res.passwordlenghtmustbe6atleast;
                this.Alreadyaregistereduser = res.Alreadyaregistereduser;
                this.SignupNow = res.SignupNow;
                this.Login = res.Login;
                this.SignStudentNow = res.SignStudentNow;
                this.SignInstructorNow = res.SignInstructorNow;
            });
    }

    signAsInstructor() {
        console.log( this.currentDialCode + this.registerForm.value.phone)
     
        if (this.registerForm.valid) {
            this._instructor
                .registerMaid(
                    this.registerForm.value.firstName,
                    this.registerForm.value.lastName,
                    this.registerForm.value.email,
                    this.registerForm.value.password,
                    this.currentDialCode + this.registerForm.value.phone
                )
                .subscribe(
                    (res) => {
                       console.log(res)
                       this.router.navigateByUrl("/verify-code");
                    },
                    (err) => {
                        console.log(err);
                        this.msg = err.error.message
                    }
                );
        } else {
            this.msg = "Fill all data"
        }
    }

    signAsStudent() {
        if (this.registerForm.valid) {
            this._student
                .registerStudent(
                    this.registerForm.value.firstName,
                    this.registerForm.value.lastName,
                    this.registerForm.value.email,
                    this.registerForm.value.password,
                    this.currentDialCode + this.registerForm.value.phone
                )
                .subscribe(
                    (res) => {        

                                this.router.navigateByUrl("/verify-code");
                            
                     
                    },
                    (err) => {
                        console.log(err);
                        this.msg = err.error.message
                    }
                );
        } else {
            this.msg = "Fill all data"
        }
    }

    get firstName() {
        return this.registerForm.get("firstName");
    }
    get lastName() {
        return this.registerForm.get("lastName");
    }
    get email() {
        return this.registerForm.get("email");
    }
    get password() {
        return this.registerForm.get("password");
    }
}
