
<section class="shop-area ptb-100">
    <div class="container">
   
    </div>
     
      <div class="container">
     
          <nav>
              <div class="nav nav-tabs text-center" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" (click)="changeTap('pending')">Pending Reservations</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" (click)="changeTap('done')">Past Reservations</button>
                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" (click)="changeTap('accepted')">On Going Reservation</button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                 
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Address</th>
                        <th scope="col">Maid</th>
                        <th scope="col">Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rev of allReservations">
                        <th scope="row">{{rev?.date | date}}</th>
                        <td>{{rev?.address?.name}}</td>
                        <td >
                            <span *ngFor="let maid of rev?.maid">
                                {{maid?.name}}, {{maid?.nationality}} <br>
                            </span></td>
                        <td>{{rev?.type}}</td>
                        <td>{{rev?.amount}}</td>
                        <td>{{rev?.paymentMethod}}</td>
                        <td>
                            <a class="btn btn-warning" (click)="cancle(rev.id)">
cancle
                            </a>
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
  
              </div>
              <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Address</th>
                      <th scope="col">Maid</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rev of allReservations">
                      <th scope="row">{{rev?.date | date}}</th>
                      <td>{{rev?.address?.name}}</td>
                      <td >
                          <span *ngFor="let maid of rev?.maid">
                              {{maid?.name}}, {{maid?.nationality}} <br>
                          </span></td>
                      <td>{{rev?.type}}</td>
                      <td>{{rev?.amount}}</td>
                      <td>{{rev?.paymentMethod}}</td>
                    
                    </tr>
                   
                  </tbody>
                </table>

  
            </div>
  
              <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Address</th>
                      <th scope="col">Maid</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rev of allReservations">
                      <th scope="row">{{rev?.date | date}}</th>
                      <td>{{rev?.address?.name}}</td>
                      <td >
                          <span *ngFor="let maid of rev?.maid">
                              {{maid?.name}}, {{maid?.nationality}} <br>
                          </span></td>
                      <td>{{rev?.type}}</td>
                      <td>{{rev?.amount}}</td>
                      <td>{{rev?.paymentMethod}}</td>
                      <td>
                        <span style="color: green;" *ngIf="rev?.paymentMethod=='cash'"> ** you ordered by cash, if you need to PAY NOW!, please checkout</span>
                         <br> <a  class="btn btn-warning" routerLink="/checkout/{{rev?.id}}" >
Checkout
                          </a>
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
                
              </div>
            </div>
          </div>
  </section>