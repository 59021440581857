import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { environment as env } from "../../../../environments/environment.prod";
import {
    StripeService,
    StripePaymentElementComponent,
    StripeCardNumberComponent,
} from "ngx-stripe";
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentIntent,
    StripeElements,
    StripeCardElement,
} from "@stripe/stripe-js";
import { CouponsService } from "src/app/services/coupons.service";
import { OrderService } from "src/app/services/order.service";
import { environment } from "src/environments/environment.prod";

@Component({
    selector: "app-checkout",
    templateUrl: "./checkout.component.html",
    styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
    // @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
    elements: StripeElements;
    card: StripeCardElement;

    user: any = {};
    msg: any = null;
    cartItems:any;
    total: any = 0;
    checkoutForm: any;
    lang = "en";
    done: any = null;
    Home: any;
    BillingDetails: any;
    Checkout: any;
    LastName: any;
    FirstName: any;
    Email: any;
    youmustentervalidemail: any;
    phone: any;
    Numbermustbeatleast8numbers: any;
    NameOnCard: any;
    writevalidname: any;
    CardNumber: any;
    writevalidcardnumber: any;
    ExpirationMonth: any;
    ExpirationYea: any;
    CardCVVNumber: any;
    Country: any;
    TownCity: any;
    State: any;
    Address: any;
    PostcodeZip: any;
    required: any;
    OrderNotes: any;
    YourOrder: any;
    ProductName: any;
    price: any;
    PlaceOrder: any;
    ExpirationYear: any;
    couponId: any = null;
    email2: any = null;
    discount = 0;
    name = null;
    from: any = 1;
    elementsOptions: StripeElementsOptions = {
        locale: "en",
    };
    actualItems = "";

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: "#666EE8",
                color: "#31325F",
                fontWeight: "300",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: "18px",
                "::placeholder": {
                    color: "#212529",
                },
            },
        },
    };

    stripeTest: FormGroup;
    amountAfterPromo: any = null;
    constructor(
        private auth: AuthenticationService,
        private _cart: CartService,
        private _coupon: CouponsService,
        private _order: OrderService,
        private router: Router,
        private route :ActivatedRoute,
        private _defult: DefaultService,
        public translate: TranslateService,
        private stripeService: StripeService,
        private fb: FormBuilder,
        private http: HttpClient,
        private router2: ActivatedRoute
    ) {
        this.id = this.route.snapshot.paramMap.get("id");
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            this.from = this.router2.snapshot.paramMap.get("from");
            localStorage.removeItem("previous_link");
        } else {
            this.router.navigateByUrl("/login");
        }
    }
id:any
    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }

        if (this.user) {
            this.email2 = this.user.email;
            this.name = this.user.firstName + " " + this.user.lastName;

           
            this.stripeTest = new FormGroup({
                nameOnCard: new FormControl("", [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern("^[A-Za-z][A-Za-z -]*$"),
                ]),
                email: new FormControl(this.user.email, [
                    Validators.required,
                    Validators.email,
                ]),

                firstName: new FormControl(this.user.firstName, [
                    Validators.required,
                ]),
                lastName: new FormControl(this.user.lastName, [
                    Validators.required,
                ]),
                mobile: new FormControl(this.user.phone, [
                    Validators.required,
                    Validators.minLength(8),
                ]),
                coupon: new FormControl("", []),
            });
          this.stripeService
                .elements(this.elementsOptions)
                .subscribe((elements) => {
                    this.elements = elements;
                    // Only mount the element the first time
                    if (!this.card) {
                        this.card = this.elements.create(
                            "card",
                            this.cardOptions
                        );
                        this.card.mount("#card-element");
                    }
                });
        } else {
            this.router.navigate(["/login"]);
        }

        this.getData();
    }



    getData() {
     
this.http.get<any>(environment.BaseUrl +`/reserve/${this.id}`).subscribe(
    (res)=>{
console.log(res)
this.cartItems = res.data
this.total = res.data.amount
    }
)
    
    }

  

    getTotal() {
        var total = 0;
        this.cartItems.forEach((e) => {
            var price = e.price;

            if (e.discount > 0) {
                price = e.discount;
            }

            total = Number(total) + Number(price);
        });
        return total;
    }

 

    pay(): void {
        this.done = true;
        this.msg = "Please, wait a minute";
        if (this.stripeTest.valid) {
            let total = this.total

            if (
                this.stripeTest.value.coupon &&
                this.stripeTest.value.coupon.length > 0
            ) {
                this._coupon
                    .checkCoupon(this.stripeTest.value.coupon , this.total, this.cartItems.type)
                    .subscribe(
                        (res) => {
                           console.log("Coupon",res)
                           this.amountAfterPromo = res.data.amountAfterPromo
                           this.msg = `your Total updated to ${this.amountAfterPromo}`
                           this.completePayment(this.amountAfterPromo);
                        },
                        (err) => {
                            this.done = false;
                            this.msg = `coupon code  is not valid or not for ${this.cartItems.type}`;
                        }
                    );
            } else {
                this.completePayment(total);
            }
        } else {
            this.done = false;
            this.msg = "please check your information";
        }
    }

    completePayment(total) {


        this.createPaymentIntent(total)
            .pipe(
                switchMap((pi) =>
                    this.stripeService.confirmCardPayment(pi.client_secret, {
                        payment_method: {
                            card: this.card,
                            billing_details: {
                                name: this.stripeTest.value.nameOnCard,
                                email: this.stripeTest.value.email,
                            },
                        },
                    })
                )
            )
            .subscribe((result) => {
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)

                    this.done = false;
                    this.msg = result.error.message;
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === "succeeded") {
                        // Show a success message to your customer
                     
                      
                    }
                }
            });
    }



    createPaymentIntent(amount: any): Observable<PaymentIntent> {
        return this.http.post<PaymentIntent>(
            `${env.BaseUrl}/create-payment-intent`,
            { amount: amount }
        );
    }
    get firstName() {
        return this.stripeTest.get("firstName");
    }
    get lastName() {
        return this.stripeTest.get("lastName");
    }
    get email() {
        return this.stripeTest.get("email");
    }
    get mobile() {
        return this.stripeTest.get("mobile");
    }

    get coupon() {
        return this.stripeTest.get("coupon");
    }

    get nameOnCard() {
        return this.stripeTest.get("nameOnCard");
    }
    /*get cardNumber() {
        return this.stripeTest.get("cardNumber");
    }
    get expirationMonth() {
        return this.checkoutForm.get("expirationMonth");
    }
    get expirationYear() {
        return this.checkoutForm.get("expirationYear");
    }
    get cardCVVNumber() {
        return this.checkoutForm.get("cardCVVNumber");
    }

    get country() {
        return this.checkoutForm.get("country");
    }
    get city() {
        return this.checkoutForm.get("city");
    }
    get state() {
        return this.checkoutForm.get("state");
    }
    get postal() {
        return this.checkoutForm.get("postal");
    }
    get address() {
        return this.checkoutForm.get("address");
    }*/
}
