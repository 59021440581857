import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private _http : HttpClient) { }
  getAllBooks(catId?:any,search?:any,sortCol?:any,sortType?:any,start?:any,per_page?:any){
    if(!sortCol){
        sortCol=null;
        sortType=''
      }

    return this._http.post<any>(environment.BaseUrl+'/book/getAllBook',{
        catId:catId,
        search:search,
        sortCol:sortCol,
        sortType:sortType,
        start:start,
        per_page:per_page
    });
  }
   getOneBook(id?:any){
    return this._http.post<any>(environment.BaseUrl+`/book/getOne`,{id});
  }

  getCommentsForOneBook(id?:any){
    return this._http.post<any>(environment.BaseUrl+`/book_rate/getComments`,{id});
  }

  rateBook(userId:any,bookId:any,star:any){
    return this._http.post(environment.BaseUrl+`/book_rate/rateBook`,{
     bookId:bookId,
      userId:userId,
      star:star
    });
  }

  reviewBook(userId:any,bookId:any,review:any){
    return this._http.post(environment.BaseUrl+`/book_rate/reviewBook/${bookId}`,{
      userId:userId,
      Comment:review
    });
  }
  getInstructorBooks(userId?:any,sortType?:any,sortCol?:any,start?:any,per_page?:any){
    if(!sortCol){
        sortCol=null;
        sortType=''
      }
    return this._http.post<any>(environment.BaseUrl+`/book/getInstructorBooks/${userId}`,{sortType:sortType,sortCol:sortCol,start:start,
        per_page:per_page});
    }
}
