<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ResetPassword}}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ ResetPassword}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>{{ResetPassword}}</h3>
                <p>{{PleaseWriteTheNewPassword}}</p>
            </div>

            <div class="row text-center alert alert-success" *ngIf="msg !=null&& error">
                {{yourpasswordwaschangedyoucan}} <a style="width:30%" routerLink="/login">{{Login}}</a>
            </div>

            <div class="row text-center alert alert-danger " *ngIf="msg!=null && !error">
                {{Thelinkhasexpiredclickon}}<a style="width:40%" routerLink="/forgetPassword">{{ForgetYourPassword}}</a>
            </div>

            <form id="formLogin ">
                <div class="row ">
                    <div class="col-lg-12 text-center">
                        <div class="form-group">
                            <label>Code</label>
                            <input type="text" class="form-control text-center" placeholder="- - - -" #code />
                            <a id="error" style="color: red"></a>
                        </div>
                    </div>
                    <div class="col-lg-12 ">
                        <div class="form-group ">
                            <input type="password" class="form-control " placeholder="Password " #pass id='pass' />
                            <a id="error" style="color: red "></a>
                        </div>
                    </div>


                    <div class="col-lg-12 ">
                        <div class="form-group ">
                            <input type="password" class="form-control " placeholder="Confirm Password " #conpass id='conpass' />
                            <a id="error2" style="color: red "></a>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 ">
                        <div class="form-check ">
                            <input type="checkbox " class="form-check-input " id="checkme ">
                            <label class="form-check-label " for="checkme ">Keep me Login</label>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-12 text-end ">
                        <p class="forgot-password ">
                            <a routerLink="/ ">Forgot Password?</a>
                        </p>
                    </div> -->

                    <div class="col-lg-12 ">
                        <div class="send-btn ">
                            <a (click)="confirm(pass,conpass , code) " class="default-btn-one ">{{Confirm}}</a>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>