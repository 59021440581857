<app-navbar-two></app-navbar-two>
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Verify Account</h2>

                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>Verify Account</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>Verify SMS code</h3>
   
            </div>

    




            <form id="formLogin">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="form-group">
                            <input type="text" class="form-control text-center" placeholder="- - - -" #code />
                            <a id="error" style="color: red"></a>
                        </div>
                    </div>



                    <!-- <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep me Login</label>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-12 text-end">
                        <p class="forgot-password">
                            <a routerLink="/">Forgot Password?</a>
                        </p>
                    </div> -->


                    <div class="col-lg-12 text-center">
                        <div class="send-btn ">
                            <a (click)="confirm(code)" class="default-btn-one">Verify</a>
                        </div>
                        <a (click)="resend()" style="cursor: pointer;"> Re-Send OTP ??</a>

                    </div>


                </div>
            </form>
        </div>
    </div>
</div>
