<div class="navbar-area-two">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/lg.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/lg.png" alt="logo" width="150" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Home </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">About Us</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/promos" class="nav-link">Promo Codes
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/maids" class="nav-link">Maids
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/book-reservation" class="nav-link">Book Reservation
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/offers" class="nav-link">Offers</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/affiliate" routerLinkActive="active" [routerLinkActiveOptions]="{
                exact: true
            }" class="nav-link">Affilliate Program
        </a>
                        </li> -->
                        <li>
                            <div class="others-options seo-option">
                                <!-- <div class="language-list">
                                    <div class="select-box">
                                        <select id="lang" class="selectLang">
                                            <option value="en">En</option>
                                            <option value="ar">Ar</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="seo-cart-icon">
                                

                                    <a routerLink="/notifiy">
                                        <i class="fa fa-bell"></i>
                                        <span class="point" *ngIf="notify > 0">{{
                                            notify
                                        }}</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="!isAuthorized" class="nav-link" routerLink="/login">{{ Login }}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="!isAuthorized" class="nav-link" routerLink="/signup">{{ SignUp }}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="isAuthorized" class="nav-link" routerLink="/profile">{{ Profile }}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="isAuthorized" class="nav-link" (click)="logout()">{{ Logout }}</a>
                        </li>
                    </ul>

                    <!--  <div class="others-options saas-option">

           <nav *ngIf="isAuthorized" id="menu" class="main-menu">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <img style="width: 45px; height: 45px; border-radius: 50%;margin-top:30px;" [src]="BaseUrl + '/' + userStrsing.image" />
                        <ul aria-labelledby="navbarDropdownMenuLink">

                            <li class="dropdown-item">
                                <a [routerLink]='["/profile"]' *ngIf="userStrsing.type=='student'">Profile</a>
                            <a [routerLink]='["/instructor-panel"]' *ngIf="userStrsing.type=='instructor'">Profile</a>
                        </li>
                        <li class="dropdown-item">
                            <a href="" (click)="logout()">Logout</a>
                        </li>
                    </ul>
                    </li>
                    </ul>
            </nav>

            </div>-->
                </div>
            </nav>
        </div>
    </div>
</div>