import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FilterByNamePipe } from './services/filterbyname.pipe';

import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClient,
  } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
//import { OwlModule ,OwlCarousel} from "ngx-owl-carousel";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ImageCropperModule } from "ngx-image-cropper";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { NavbarOneComponent } from "./components/layouts/navbar-one/navbar-one.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { NavbarTwoComponent } from "./components/layouts/navbar-two/navbar-two.component";
import { NavbarThreeComponent } from "./components/layouts/navbar-three/navbar-three.component";
import { NavbarFourComponent } from "./components/layouts/navbar-four/navbar-four.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { BookComponent } from "./components/pages/book/book.component";
import { BookDetailsComponent } from "./components/pages/book-details/book-details.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { CoursesListComponent } from "./components/pages/courses-list/courses-list.component";
import { CourseDetailsComponent } from "./components/pages/course-details/course-details.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MybooksComponent } from "./components/pages/mybooks/mybooks.component";
import { MyCoursesComponent } from "./components/pages/my-courses/my-courses.component";
import { MyCertificatesComponent } from "./components/pages/my-certificates/my-certificates.component";
import { ShowMyCoursesComponent } from "./components/pages/show-my-courses/show-my-courses.component";
import { InstructorCoursesComponent } from "./components/pages/instructor-courses/instructor-courses.component";
import { NgxStripeModule } from "ngx-stripe";
import { AffiliateComponent } from "./components/pages/affiliate/affiliate.component";
import { BlackCertificateComponent } from "./components/pages/certificate_images/black-certificate/black-certificate.component";
import { BlackCertificateBigComponent } from "./components/pages/certificate_images/black-certificate-big/black-certificate-big.component";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";
import { ForgetPasswordComponent } from "./components/pages/forgetPassword/forgetPassword.component";
import { ResetPasswordComponent } from "./components/pages/resetPassword/resetPassword.component";
import { ChangePasswordComponent } from "./components/pages/changePassword/changePassword.component";
import { SingleMaidComponent } from './components/pages/single-maid/single-maid.component';
import { VerifyCodeComponent } from './components/pages/verify-code/verify-code.component';
import { JwtInterceptor } from "./services/jwt.interceptor";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        FilterByNamePipe,
        PreloaderComponent,
        FooterComponent,

        NavbarOneComponent,
        HomeComponent,
        NavbarTwoComponent,
        NavbarThreeComponent,
        NavbarFourComponent,
        AboutComponent,
        BookComponent,
        BookDetailsComponent,

        TeamComponent,
        FaqComponent,

        LoginComponent,
        SignupComponent,
        ErrorComponent,
        ForgetPasswordComponent,
        ResetPasswordComponent,

        CoursesListComponent,
        CourseDetailsComponent,
        CartComponent,
        CheckoutComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        ProfileComponent,
        MybooksComponent,
        MyCoursesComponent,
        ShowMyCoursesComponent,
        InstructorCoursesComponent,
        AffiliateComponent,
        MyCertificatesComponent,
        BlackCertificateComponent,
        PrivacyComponent,
        ChangePasswordComponent,
        BlackCertificateBigComponent,
        SingleMaidComponent,
        VerifyCodeComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        CarouselModule,
        ReactiveFormsModule,
        
        NgxStripeModule.forRoot(
            "pk_live_51MjV8xKeovXacWQZUAVtlR6nvTvbQtl6Z596G3Ie0O25TTmXhikbt7MT7x2QKH3yTP5VDG3u8whQZ6woyzIi0skv00MnD27IcQ"
        ), //pk_test_51JJLSwAL93EluVOCu1X2I7J8U6kRzucnTruJsdUKGNJB88XddVMBMa51PSdhGIGOMlQPY6u4BIeOb0pkiIb4BRe80068csdvQw
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ImageCropperModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
    bootstrap: [AppComponent],
})
//    "pk_live_51JJLSwAL93EluVOCTa1Uypg9bl1D78j9bKsOIPNRq8W8PxC33ge7nx4jiVFlGsNyA7JhQDzEDIq64kZDdNMuiThr00q2bsAGPj"
export class AppModule {}
