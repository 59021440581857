<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Book Reservation</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>Book Reservation</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="shop-area ptb-100">
  <div class="container">
    <div class="row text-center alert alert-danger" *ngIf="message">
      {{ message }}
  </div>
  </div>
   
    <div class="container" *ngIf="!message">
   
        <nav>
            <div class="nav nav-tabs text-center" id="nav-tab" role="tablist">
              <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Single Reservation</button>
              <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Package Reservation</button>
              <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contract Reservation</button>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <h1>Service Details: Total : {{total}} AED</h1>
<div class="row">
    <p>How many professionals do you need?</p>

<div id="numberList">
  <span class="circle" *ngFor="let number of numbers; let i = index" [class.selected]="selectedNumber === number" (click)="selectNumber(number)">
    {{ number }}
  </span>
</div>
</div>

<div class="row">
    <p>How many hours do you need to your professional to stay?</p>

<div id="dayList">
  <span class="circle" *ngFor="let number of hours; let i = index" [class.selected]="selectedHour === number" (click)="selectHour(number)">
    {{ number }}
  </span>
</div>
</div>

<div class="row">
    <p>When would you like your service?</p>

<div id="hoursList">
  <span class="circle" *ngFor="let number of days; let i = index" [class.selected]="selectedDay === number" (click)="selectDay(number)">
    {{ number| date:'EEE MMM d' }}
  </span>
</div>
</div>

<div class="row">
    <p>Do you require cleaning matrials?</p>

<div id="matrialsList">
  <span class="circle" *ngFor="let number of mats; let i = index" [class.selected]="selectedMats === number" (click)="selectMats(number)">
    {{ number}}
  </span>
</div>
</div>
<br>
<p>You have to select day first to show you how many maids are free at this day </p>

<div class="row">
    <p>Which Nationality do you prefer?</p>

<div id="nationList">
  <span class="circle2" *ngFor="let number of nation; let i = index" [class.selected]="selectednations === number" (click)="selectNation(number)">
    {{ number}}
  </span>
</div>
</div>

<div class="row">
  
    <p>Choose Only {{selectedNumber}}</p>

<div id="maidList">
  <span class="circle3" *ngFor="let number of filteredPeople; let i = index" [class.selected]="selectedMaid.includes(number.id)" (click)="selectMaid(number.id)">
    <img [src]="number?.photo" />
    {{ number?.name}}
  </span>
</div>
</div>


<div class="row">
  <p>Do you have any notes?</p>
  
<textarea class="form-control" minlength="30" name="notes" #notes>

</textarea>
</div>
<div class="row">
  <p>Choose of your Address</p>
  <div id="addrList">
    <span class="circle3" *ngFor="let number of addresses; let i = index" [class.selected]="selectedAddress === number.id" (click)="selectAddress(number.id)">
    
      {{ number?.name}}
      <br>
      {{ number?.city}}
     ,
      {{ number?.country}}
      <br>
      {{ number?.postal}}
      ,
      {{ number?.street}}


    </span>
  </div>
<h3>Or Add New Address</h3>
<div class="col-lg-7">
  <form class="form-group" method="POST">
    <label for="name">Name:</label>
    <input class="form-control col-lg-6" type="text" #name name="name" required>
    
    <label for="street">Street:</label>
    <input class="form-control col-lg-6" type="text" #street name="street" required>
    
    <label for="city">City:</label>
    <input class="form-control col-lg-6" type="text" #city name="city" required>
    
    <label for="postal">Postal Code:</label>
    <input class="form-control col-lg-6" type="text" #postal name="postal" required>
    
    <label for="country">Country:</label>
    <input class="form-control col-lg-6" type="text" #country name="country" required>
    
    <label for="floarNo">Floor Number:</label>
    <input class="form-control col-lg-6" type="text" #floarNo name="floarNo" required>
    
    <input  type="submit" class=" submit btn btn-warning" value="Add Address" (click)="addnewAddress(name,street,city,postal,country,floarNo)">
  </form>
</div>
</div>
<div>
    Total Price for {{selectedNumber}} Maid with nation {{selectednations}} is: {{total}} AED 

    <div id="paymentMethod">
      <p>Choose your payment method</p>
      <span class="circle2" *ngFor="let number of paymentMethod; let i = index" [class.selected]="selectedpaymentMethod === number" (click)="selectpaymentMethod(number)">
        {{ number}}
      </span>
    </div>
    <button class="btn btn-warning" (click)="orderNow()" >Order Now</button>
</div>

            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <h1>Service Details: Total : {{total}} AED</h1>
<div class="row">
  <p>How many professionals do you need?</p>

<div id="numberList">
<span class="circle" *ngFor="let number of numbers; let i = index" [class.selected]="selectedNumber === number" (click)="selectNumber(number)">
  {{ number }}
</span>
</div>
</div>



<div class="row">

  <p>How many Months do you need your professionals to stay?</p>

<div id="numberList">
<span class="circle" *ngFor="let number of Months; let i = index" [class.selected]="selectedMonths === number" (click)="selectMonths(number)">
  {{ number }}
</span>
</div>
</div>

<div class="row">
  <p>How many days a week do you need your professionals to stay?</p>

<div id="numberList">
<span class="circle" *ngFor="let number of daysWeek; let i = index" [class.selected]="selecteddaysWeek === number" (click)="selectDayWeek(number)">
  {{ number }}
</span>
</div>
</div>

<div class="row">
  <p>How many hours do you need to your professional to stay?</p>

<div id="dayList">
<span class="circle" *ngFor="let number of hours; let i = index" [class.selected]="selectedHour === number" (click)="selectHour(number)">
  {{ number }}
</span>
</div>
</div>

<div class="row">
  <p>When would you like your service?</p>

<div id="hoursList">
  <span class="circle2" *ngFor="let hour of hourRanges; let i = index" [class.selected]="selectedHours === hour" (click)="selectHours(hour)">
    {{ hour }}
  </span>
</div>
</div>

<div class="row">
  <p>Do you require cleaning matrials?</p>

<div id="matrialsList">
<span class="circle" *ngFor="let number of mats; let i = index" [class.selected]="selectedMats === number" (click)="selectMats(number)">
  {{ number}}
</span>
</div>
</div>
<br>


<div class="row">
  <p>Which Nationality do you prefer?</p>

<div id="nationList">
<span class="circle2" *ngFor="let number of nationalities; let i = index" [class.selected]="selectednations === number.name" (click)="selectNation(number.name)">
  {{ number?.name}}
</span>
</div>
</div>




<div class="row">
<p>Do you have any notes?</p>

<textarea class="form-control" minlength="30" name="notes" #notes>

</textarea>
</div>
<div class="row">
<p>Choose of your Address</p>
<div id="addrList">
  <span class="circle3" *ngFor="let number of addresses; let i = index" [class.selected]="selectedAddress === number.id" (click)="selectAddress(number.id)">
  
    {{ number?.name}}
    <br>
    {{ number?.city}}
   ,
    {{ number?.country}}
    <br>
    {{ number?.postal}}
    ,
    {{ number?.street}}


  </span>
</div>
<h3>Or Add New Address</h3>
<div class="col-lg-7">
<form class="form-group" method="POST">
  <label for="name">Name:</label>
  <input class="form-control col-lg-6" type="text" #name name="name" required>
  
  <label for="street">Street:</label>
  <input class="form-control col-lg-6" type="text" #street name="street" required>
  
  <label for="city">City:</label>
  <input class="form-control col-lg-6" type="text" #city name="city" required>
  
  <label for="postal">Postal Code:</label>
  <input class="form-control col-lg-6" type="text" #postal name="postal" required>
  
  <label for="country">Country:</label>
  <input class="form-control col-lg-6" type="text" #country name="country" required>
  
  <label for="floarNo">Floor Number:</label>
  <input class="form-control col-lg-6" type="text" #floarNo name="floarNo" required>
  
  <input  type="submit" class=" submit btn btn-warning" value="Add Address" (click)="addnewAddress(name,street,city,postal,country,floarNo)">
</form>
</div>
</div>
<div>
  Total Price for {{selectedNumber}} Maid with nation {{selectednations}} is: {{total}} AED 

  <div id="paymentMethod">
    <p>Choose your payment method</p>
    <span class="circle2" *ngFor="let number of paymentMethod; let i = index" [class.selected]="selectedpaymentMethod === number" (click)="selectpaymentMethod(number)">
      {{ number}}
    </span>
  </div>
  <button class="btn btn-warning" (click)="bookPack()"  >Order Now</button>
</div>

          </div>

            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <h1>Service Details: Total : {{total}} AED</h1>
              <div class="row">
                <p>Where are you?</p>
            
            <div id="nationList">
              <span class="circle2" *ngFor="let number of locations; let i = index" [class.selected]="selectedLocation === number" (click)="selectLocation(number)">
                {{ number}}
              </span>
            </div>
            </div>

              <div class="row">
  
             
            
            <div id="maidList">
              <span class="circle4" *ngFor="let number of onApp; let i = index" [class.selected]="selectedMaidC == number.id" (click)="selectMaidC(number.id)">
                <img [src]="number?.photo" />
                {{ number?.name}}
                <br>
                {{ number?.experience}} experience
                <br>
                {{ number?.salary}} salary
                <br>
              
                <a href="{{number?.resume}}">Download CV</a>
              </span>
            </div>
            </div>

              <div class="row">
                <p>Do you have any notes?</p>
                
                <textarea class="form-control" minlength="30" name="notes" #notes>
                
                </textarea>
                </div>
                <div class="row">
                <p>Choose of your Address</p>
                <div id="addrList">
                  <span class="circle3" *ngFor="let number of addresses; let i = index" [class.selected]="selectedAddress === number.id" (click)="selectAddress(number.id)">
                  
                    {{ number?.name}}
                    <br>
                    {{ number?.city}}
                   ,
                    {{ number?.country}}
                    <br>
                    {{ number?.postal}}
                    ,
                    {{ number?.street}}
                
                
                  </span>
                </div>
                <h3>Or Add New Address</h3>
                <div class="col-lg-7">
                <form class="form-group" method="POST">
                  <label for="name">Name:</label>
                  <input class="form-control col-lg-6" type="text" #name name="name" required>
                  
                  <label for="street">Street:</label>
                  <input class="form-control col-lg-6" type="text" #street name="street" required>
                  
                  <label for="city">City:</label>
                  <input class="form-control col-lg-6" type="text" #city name="city" required>
                  
                  <label for="postal">Postal Code:</label>
                  <input class="form-control col-lg-6" type="text" #postal name="postal" required>
                  
                  <label for="country">Country:</label>
                  <input class="form-control col-lg-6" type="text" #country name="country" required>
                  
                  <label for="floarNo">Floor Number:</label>
                  <input class="form-control col-lg-6" type="text" #floarNo name="floarNo" required>
                  
                  <input  type="submit" class=" submit btn btn-warning" value="Add Address" (click)="addnewAddress(name,street,city,postal,country,floarNo)">
                </form>
                </div>
                </div>


              <div>
                Total Price for {{selectedNumber}} Maid with nation {{selectednations}} is: {{total}} AED 
              
                <div id="paymentMethod">
                  <p>Choose your payment method</p>
                  <span class="circle2" *ngFor="let number of paymentMethod; let i = index" [class.selected]="selectedpaymentMethod === number" (click)="selectpaymentMethod(number)">
                    {{ number}}
                  </span>
                </div>
                <button class="btn btn-warning" (click)="contract(notes)" >Order Now</button>
              </div>
              
            </div>
          </div>
        </div>
</section>