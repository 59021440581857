import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { CertificationsService } from "../../../services/certifications.service";
import { CourseService } from "../../../services/course.service";
import { DefultService } from "src/app/services/defult/defult.service";
import { environment } from "src/environments/environment.prod";
declare var $: any;
@Component({
    selector: "app-my-certificates",
    templateUrl: "./my-certificates.component.html",
    styleUrls: ["./my-certificates.component.scss"],
})
export class MyCertificatesComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    courses = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    modal = false;
    CoursesList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;
    user: any = {};
    certifications = [];
    certificateImg: any;
    valid: any;
    nameincert: any;
    numberinCert: any;
    titleincert: any;
    instructorId: any;
    certificateId: any;
    instructorCert: any;

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private _course: CourseService,
        private _certificate: CertificationsService,
        private _defult: DefultService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            // this.translate.use('ar');
        } else {
            this.lang = "en";
            //  this.translate.use('en');
        }
        this.getLabels();

        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
        } else {
            this.router.navigateByUrl("/");
        }

        this.getData();
        var app = this;
        $(document).ready(function () {
            $("select").change(function () {
                app.getData($(this).val(), 2, 0);
            });
        });
    }

    openCertificateClickOn2(cert) {
        this.certificateImg = this.baseUrl + "/" + cert.certificate;
        this.valid = cert.validthru;
        this.numberinCert = cert.number;
        this.nameincert = cert.student;
        this.titleincert = cert.title;
        this.instructorCert = cert.instructor;
        this.modal = true;
    }

    closeModal() {
        this.modal = false;
    }

    getLabels() {
        /* this.translate
          .get([
            'Home',
            'CoursesList',
            'Showing',
            'results',
            'of',
            'AddToCart',
            'RemovefromCart'

          ])
          .subscribe((res) => {
            this.Home = res.Home;
            this.CoursesList = res.CoursesList;
            this.Showing = res.Showing;
            this.results = res.results;
            this.of = res.of;
            this.AddToCart = res.AddToCart;
            this.RemovefromCart = res.RemovefromCart;
          })*/
    }

    changeOffset() {
        this.getData();
    }

    getData(sort?: any, limit?: any, offset?: any) {
        this._certificate.getUserCertificates(this.user.id).subscribe(
            (res) => {
                if (res.error == 0) {
                    this.certifications = res.data;
                }
            },
            (err) => {
                //console.log(err);
                // console.log(err)
            },

            () => {
                // console.log('sucess');
            }
        );
    }
    getDate(date) {
        let d = new Date(date);

        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth() + 1; //monthes
        let year = d.getUTCFullYear(); //year

        return mon + " / " + day + " / " + year;
    }
}
