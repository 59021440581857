import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";

import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
@Component({
    selector: "app-forget-password",
    templateUrl: "./forgetPassword.component.html",
    styleUrls: ["./forgetPassword.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
    key: any[] = [];

    LoginwithFacebook: any;
    LoginwithLinkedin: any;
    Yourpassword: any;
    LogintoSalesH2O: any;
    LoginwithGoogle: any;
    Youremail: any;
    Forgotpassword: any;
    Signup: any;
    WhatsMyPassword: any;
    forgetpasmessage: any;
    Cancel: any;
    Newtosalesh2o: any;
    copyRight: any;
    Or: any;
    LoginASInstuctor: any;
    points: 0;
    msg = null;
    base_url = null;
    lang = "en";
    Home: any;

    Login: any;

    Email: any;
    Password: any;
    ResetPassword: any;
    ForgetYourPassword: any;
    PleaseWriteYourEmail: any;
    PleaseCheckYourEmail: any;
    Confirm: any;

    constructor(
        private router: Router,
        public translate: TranslateService,
        private _defult: DefaultService,
        private auth: AuthenticationService,
        private student: StudentService
    ) {
        this.base_url = window.location.origin;
        if (auth.currentUser()) {
            this.router.navigateByUrl("/");
        }
        var app = this;
        $(document).keypress(function (e) {
            if (e.which == 13) {
                app.confirm2($("#email"));
            }
        });
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Login",
                "Email",
                "Password",
                "ForgetYourPassword",
                "ResetPassword",
                "PleaseWriteYourEmail",
                "PleaseCheckYourEmail",
                "Confirm",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Login = res.Login;
                this.Email = res.Email;
                this.Password = res.Password;
                this.ForgetYourPassword = res.ForgetYourPassword;
                this.ResetPassword = res.ResetPassword;
                this.Confirm = res.Confirm;
                this.PleaseWriteYourEmail = res.PleaseWriteYourEmail;
                this.PleaseCheckYourEmail = res.PleaseCheckYourEmail;
            });
    }

    // getLabels() {
    //     this.translate
    //         .get([
    //             "LoginwithFacebook",
    //             "LoginwithLinkedin",
    //             "Yourpassword",
    //             "LogintoSalesH2O",
    //             "LoginwithGoogle",
    //             "Youremail",
    //             "Forgotpassword",
    //             "Signup",
    //             "WhatsMyPassword",
    //             "forgetpasmessage",
    //             "Cancel",
    //             "Newtosalesh2o",
    //             "copyRight",
    //             "Or",
    //             "LoginASInstuctor",
    //         ])
    //         .subscribe((res) => {
    //             this.LoginwithFacebook = res.LoginwithFacebook;
    //             this.LoginwithLinkedin = res.LoginwithLinkedin;
    //             this.Yourpassword = res.Yourpassword;
    //             this.LogintoSalesH2O = res.LogintoSalesH2O;
    //             this.LoginwithGoogle = res.LoginwithGoogle;
    //             this.Youremail = res.Youremail;
    //             this.Forgotpassword = res.Forgotpassword;
    //             this.Signup = res.Signup;
    //             this.WhatsMyPassword = res.WhatsMyPassword;
    //             this.forgetpasmessage = res.forgetpasmessage;
    //             this.Cancel = res.Cancel;
    //             this.Newtosalesh2o = res.Newtosalesh2o;
    //             this.copyRight = res.copyright;
    //             this.Or = res.or;
    //             this.LoginASInstuctor = res.LoginASInstuctor;
    //         });
    // }

    ngOnDestroy() {
        /* let hh = <HTMLFormElement>document.getElementById('header');
    let ff = <HTMLFormElement>document.getElementById('footer');
    hh.style.display = 'block';
    ff.style.display = 'block';*/
    }

    validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    isLoading: boolean = false;

    confirm(email) {
        let app = this;
        let num = 0;
        if (email.value == "") {
            email.style.border = "1px solid red";
            email.parentNode.querySelector("#error").innerHTML =
                "Email is Required!";
            num++;
        } else if (!this.validateEmail(email.value)) {
            email.style.border = "1px solid red";
            email.parentNode.querySelector("#error").innerHTML =
                "Not Valid Email Format";
            num++;
        } else {
            email.parentNode.style.border = "none";
            email.parentNode.querySelector("#error").innerHTML = "";
        }

        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student.forgetPassword(email.value, this.base_url).subscribe(
                (res) => {
                   console.log(res)
                   this.router.navigateByUrl("/resetPassword");
                },
                (err) => {
                    this.msg = "Error .";
                }
            );
        }
    }

    confirm2(email) {
        let app = this;
        let num = 0;
        if (email.val() == "") {
            email.css({
                border: "1px solid red",
            });
            $("#error").html("Email is Required!");
            num++;
        } else if (!this.validateEmail(email.val())) {
            email.css({
                border: "1px solid red",
            });
            $("#error").html("Not Valid Email Format");
            num++;
        } else {
            email.css({
                border: "none",
            });
            $("#error").html("");
        }
        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student.forgetPassword(email.val(), this.base_url).subscribe(
                (res) => {
                    if (res.error == 0) {
                        this.msg = "Please check your email";
                    }
                },
                (err) => {
                    this.msg = "Error";
                }
            );
        }
    }
}
