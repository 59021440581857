<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Signup }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Signup }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="signup-section ptb-100">
    <div class="container">
        <div class="signup-form">
            <h3>{{ CreateyourAccount }}</h3>
            <form method="post" id="contactform" autocomplete="off" [formGroup]="registerForm">
                <div class="alert alert-danger" *ngIf="msg != null">
                    {{ msg }}
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="text" class="form-control" [placeholder]="[FirstName]" formControlName="firstName" />
                            <div *ngIf="
                                    firstName?.invalid &&
                                    (firstName?.touched || firstName?.dirty)
                                ">
                                <div *ngIf="firstName?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="text " class="form-control" [placeholder]="[LastName]" formControlName="lastName" />
                            <div *ngIf="
                                    lastName?.invalid &&
                                    (lastName?.touched || lastName?.dirty)
                                ">
                                <div *ngIf="lastName?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                            </div>
                        </div>
                    </div>
<div class="col-lg-12">
    <div class="form-group">
        <input id="phone" class="form-control" name="phone" formControlName="phone"  type="tel" value="" />
    </div>
</div>
                    <div class="col-lg-12">
                        <div class="form-group">
                           

                            <input type="text " class="form-control" [placeholder]="[Email]" formControlName="email" />
                            <div *ngIf="
                                    email?.invalid &&
                                    (email?.touched || email?.dirty)
                                ">
                                <div *ngIf="email?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                                <div *ngIf="email?.errors?.email" class="text-danger">
                                    {{ youmustentervalidemail }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                            <div *ngIf="
                                    password?.invalid &&
                                    (password.touched || password?.dirty)
                                ">
                                <div *ngIf="password?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                                <div *ngIf="password?.errors?.minLength" class="text-danger">
                                    {{ passwordlenghtmustbe6atleast }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 ">
                        <div class="form-check ">
                            <input type="checkbox " class="form-check-input " id="checkme ">
                            <label class="form-check-label " for="checkme ">Keep Me Signup</label>
                        </div>
                    </div>-->

                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button type="submit" class="default-btn-one" style="margin-left: 20px"   (click)="signAsStudent()">
                              SignUp As Client
                            </button>
                            <button
                                type="submit"
                                class="default-btn-one"
                                style="margin-left: 20px"
                                (click)="signAsInstructor()"
                            >
                         SignUp As Maid
                            </button>
                        </div>
                        <br />{{ Alreadyaregistereduser }}?<a routerLink="/login">Login!</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>