import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-black-certificate",
    templateUrl: "./black-certificate.component.html",
    styleUrls: ["./black-certificate.component.scss"],
})
export class BlackCertificateComponent implements OnInit {
    @Input() certificate: string;
    @Input() name: string;
    @Input() valid: string;
    @Input() number: string;
    @Input() title: string;
    @Input() type: string;
    @Input() instructor: string;
    @Input() cID: string;

    constructor() {
    }

    ngOnInit(): void {
        let id = "#" + this.cID + " canvas";

        var c: any = document.querySelector(id);
        var ctx = c.getContext("2d");



        let base_image = new Image();
        base_image.src = this.certificate;

        let app = this;
        let name = "";

        if (app.name) {
            name = app.name.replace(/^./, app.name[0].toUpperCase());
        }

        base_image.onload = function () {
            c.width = 300;
            c.height = 250;
            ctx.drawImage(base_image, 0, 0, 300, 250);
            ctx.textAlign = "left";
            ctx.fillStyle = "white";
            ctx.font = "17px emoji";
            ctx.fillText(name, 50, 165);
            ctx.font = "17px emoji";
            ctx.fillText(app.title, 80, 85);

            ctx.font = "8px emoji";
            ctx.fillStyle = "white";
            ctx.fillText(app.instructor, 30, 205);

            ctx.font = "8px emoji";
            ctx.fillStyle = "white";
            ctx.fillText(app.valid, 220, 205);


        };
    }
}
