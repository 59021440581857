import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(private _http: HttpClient) {


  }

  checkCoupon(code,amount,type){
    return this._http.post<any>(environment.BaseUrl+'/promos-applyin/',{code:code,amount:amount, type:type});
  }

  updateStatus(id,status){
    return this._http.post<any>(environment.BaseUrl+'/coupon/update/',{id,status});
  }
}
