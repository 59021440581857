import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { CourseService } from "src/app/services/course.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";
import { LessonService } from "src/app/services/lesson.service";
declare var $: any;

@Component({
    selector: "app-courses",
    templateUrl: "./instructor-courses.component.html",
    styleUrls: ["./instructor-courses.component.scss"],
})
export class InstructorCoursesComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    courseId: any;
    course: any = {};
    lang = "en";
    comments = [];
    courses = [];
    msg = false;
    totallessons = 0;
    lessons = [];
    section = [];
    msgText = "No products were found";
    Home: any;
    CourseDetails: any;
    reviews: any;
    Description: any;
    Reviews: any;
    AddToCart: any;
    RemovefromCart: any;
    CustomerReviews: any;
    Basedon: any;
    WriteaReview: any;
    SubmitReview: any;
    OurShop: any;
    RelatedProducts: any;
    on: any;
    Lessons: any;
    Enteryourname: any;
    Enteryouremail: any;
    Enteryourreviewatitle: any;
    Writeyourcommentshere: any;
    user: any = {};

    constructor(
        private auth: AuthenticationService,
        private routernavigate: Router,
        private _course: CourseService,
        private _cart: CartService,
        private routerNavigation: Router,
        private router: ActivatedRoute,
        private _defult: DefaultService,
        public translate: TranslateService,
        public _lesson: LessonService
    ) {}

    ngOnInit(): void {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
        } else {
            this.routernavigate.navigateByUrl("/");
        }

        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }

        this.getLabels();

        this.courseId = this.router.snapshot.paramMap.get("id");
        this.getCourse();
        this.getLessons();
        this.getCommentsForOneCourse();
    }
    getLabels() {
        this.translate
            .get([
                "Home",
                "CourseDetails",
                "reviews",
                "Description",
                "Reviews",
                "AddToCart",
                "RemovefromCart",
                "CustomerReviews",
                "Basedon",
                "WriteaReview",
                "SubmitReview",
                "OurShop",
                "RelatedProducts",
                "On",
                "Lessons",
                "Enteryourname",
                "Enteryouremail",
                "Enteryourreviewatitle",
                "Writeyourcommentshere",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.CourseDetails = res.CourseDetails;
                this.reviews = res.reviews;
                this.Description = res.Description;
                this.Reviews = res.Reviews;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
                this.CustomerReviews = res.CustomerReviews;
                this.Basedon = res.Basedon;
                this.WriteaReview = res.WriteaReview;
                this.SubmitReview = res.SubmitReview;
                this.OurShop = res.OurShop;
                this.RelatedProducts = res.RelatedProducts;
                this.on = res.On;
                this.Lessons = res.Lessons;
                this.Enteryourname = res.Enteryourname;
                this.Enteryouremail = res.Enteryouremail;
                this.Enteryourreviewatitle = res.Enteryourreviewatitle;
                this.Writeyourcommentshere = res.Writeyourcommentshere;
            });
    }

    getCourse() {
        this._course.getOneCourse(this.courseId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.course = res.data[0];

                    /* var lessons = res.data[0].lessons;
        lessons = $.parseJSON('[' + lessons + ']');


        if(lessons.length>0 && lessons[0].lessons_id !=null ){
        this.totallessons = lessons.length;


        this.getLessons(lessons);
        }*/
                }
                // console.log(this.course)
            },
            (err) => {
                // console.log(err)
            }
        );
    }

    getLessons() {
        this._lesson.getAllLessons(this.courseId).subscribe(
            (res) => {
                this.lessons = res.data;
                this.lessons.forEach((e) => {
                    let num = -1;
                    for (var i = 0; i < this.section.length; i++) {
                        if (this.section[i].en_name == e.en_section) {
                            num = i;
                        }
                    }

                    if (num == -1) {
                        this.section.push({
                            ar_name: e.ar_section,
                            en_name: e.en_section,
                            lessons: [e],
                        });
                    } else {
                        this.section[num].lessons.push(e);
                    }
                });
            },
            (err) => {}
        );
    }

    getCommentsForOneCourse() {
        this._course.getCommentsForOneCourse(this.courseId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.comments = res.data;
                }
                // console.log(this.course)
            },
            (err) => {
                //console.log(err)
            }
        );
    }
    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    goToCourse(id) {
        this.routerNavigation.navigate(["/courses-details/" + id]).then(() => {
            location.reload();
        });
    }
}
