import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

    constructor(private _http : HttpClient) { }
    getAllReviews(){
       return this._http.post<any>(environment.BaseUrl+'/review/getAll',{});
    }
}
