import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { CookieService } from "./cookie.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class InstructorService {
    constructor(private _http: HttpClient,private cook: CookieService) {}
    users: any;
    getAllInstructors(
        search?: any,
        sortCol?: any,
        sortType?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }

        return this._http.post<any>(
            environment.BaseUrl + "/instructor/getAllInstractors",
            {
                search: search,
                sortCol: sortCol,
                sortType: sortType,
                start: start,
                per_page: per_page,
            }
        );
    }
    getOneInstructor(id?: any) {
        return this._http.post<any>(
            environment.BaseUrl + `/instructor/getOne`,
            { id }
        );
    }

    loginInstructor(email: string, password: string) {
        return this._http.post<any>(
            environment.BaseUrl + "/instructor/login/",
            { email: email, password: password }
        );
    }
    registerMaid(
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        phone:string
    ) {
        return this._http.post<any>(
            environment.BaseUrl + "/signup-maid",
            {
                name:firstName+ " " + lastName,
                email: email,
                phone:phone,
                password: password,
            }
        ).pipe(
            map((data) => {
              // login successful if there's a jwt token in the response
              if (data) {
                this.users = data.data.user;
                console.log("login", this.users);
                // store user details and jwt in cookie
                this.cook.setCookie(
                  "currentUser",
                  JSON.stringify(this.users),
                  15
                );
                this.cook.setCookie("token", data.data.token, 30);
                //console.log("token", data.data.token);
              }
              return data;
            })
          );
    }

    updateProfile(
        id?: any,
        firstName?: any,
        lastName?: any,
        email?: any,
        phoneNumber?: any
    ) {
        return this._http.put<any>(environment.BaseUrl + `/instructor/${id}`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
        });
    }

    uploadProfilePhoto(image, email) {
        let formData = new FormData();
        formData.append("image", image);
        formData.append("email", email);
        return this._http.post<any>(
            environment.BaseUrl + "/instructor/uploadProfileImage",
            formData
        );
    }
}
