import { Component, OnInit } from "@angular/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
declare var $: any;
@Component({
  selector: 'app-single-maid',
  templateUrl: './single-maid.component.html',
  styleUrls: ['./single-maid.component.scss']
})
export class SingleMaidComponent implements OnInit {
  baseUrl: string = environment.BaseUrl;
  constructor(   private _instructor: InstructorService,
    private _defult: DefaultService,
    public translate: TranslateService,
    private router: ActivatedRoute,
    private auth: AuthenticationService,
    private _http : HttpClient) {
      this.id = this.router.snapshot.paramMap.get("id");

     }
id:any;
message:any = null;
  ngOnInit(): void {
    if (this.auth.currentUser()) {
      this. getAllOnApp() 
  
    }else{
      this.message="You Have To Login For Details"
    }
  }
  instructor: any
    getAllOnApp() { 
       
      this._http.get<any>(environment.BaseUrl +`/profile/${this.id}`).subscribe(
        (res) => {
        console.log(res);
        this.instructor = res.data }
        ,(err:any)=>{
            
        }
        
        );
    }
}
