<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>

                    <ul>
                        <li><a routerLink="/">{{Home}}</a></li>
                        <li>{{AboutUs}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/img/house-cleaning.png" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>{{AboutUs}}</span>
                    <h3>{{aboutUsTitle}}</h3>
                    <p >
                        Halan Cleaning Service, has been
providing different kinds of cleaning services
in Dubai for almost 6 years now. Our staff
knows how to get the job done, and we often
exceed the expectations of our clients so
that they will continue with us by having a
good services.
                    </p>
                    <!--  <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim venia.
                    </p>-->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>{{WhatWedo}}</span>
                    <h3>{{whatWeDoTitle}}</h3>
                    <p>
                        BABYSITTING <br>
CAREGIVING ASSISTANT<br>
HOUSE CLEANING SERVICE<br>
HOTEL CLEANING SERVICES<br>
OFFICE CLEANING SERVICES<br>
                    </p>
                    <!-- <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim venia.
                    </p>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/house.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

