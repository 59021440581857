<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Checkout</h2>

                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>Checkout</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="checkout-area ptb-100">
    <div class="container">


        <form method="post" id="contactform" autocomplete="off" (ngSubmit)="pay() " [formGroup]="stripeTest">

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">BillingDetails</h3>


                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>FirstName<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="firstName">
                                    <div *ngIf="
                                    firstName?.invalid &&
                                      (firstName?.touched || firstName?.dirty)
                                    ">
                                        <div *ngIf="firstName?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>LastName <span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="lastName">
                                    <div *ngIf="
                                    lastName?.invalid &&
                                      (lastName?.touched || lastName?.dirty)
                                    ">
                                        <div *ngIf="lastName?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="email">
                                    <div *ngIf="email?.invalid &&
                                    (email?.touched || email?.dirty)
                                  ">
                                        <div *ngIf="email?.errors?.required" class="text-danger">
                                            required
                                        </div>
                                        <span *ngIf="email?.errors?.email" class="text-danger">
                                            you must enter valid email
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>phone <span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="mobile">
                                    <div *ngIf="mobile?.invalid &&
                                          (mobile?.touched || mobile?.dirty)
                                        ">
                                        <div *ngIf="mobile?.errors?.required" class="text-danger">
                                            required
                                        </div>
                                        <span *ngIf="mobile?.errors?.minlength" class="text-danger">
                                            Number must beat least 8 numbers
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Name On Card <span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="nameOnCard">
                                    <div *ngIf="nameOnCard?.invalid &&(nameOnCard?.touched || nameOnCard?.dirty)">
                                        <span *ngIf="nameOnCard?.errors" class="text-danger">
                                            write valid name
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Card Number<span class="required"> </span></label>
                                    <div id="card-element" class="field"></div>
                                </div>
                            </div>

                            <!--<div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <label>Expiration Month<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="expirationMonth">
                                    <div *ngIf="
                                    expirationMonth?.invalid &&
                                      (expirationMonth?.touched ||expirationMonth?.dirty)
                                    ">
                                        <div *ngIf="expirationMonth?.errors" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <label>Expiration Year<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="expirationYear">
                                    <div *ngIf="
                                    expirationYear?.invalid &&
                                      (expirationYear?.touched || expirationYear?.dirty)
                                    ">
                                        <div *ngIf="expirationYear?.errors" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <label>Card CVV Number<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="cardCVVNumber">
                                    <div *ngIf="
                                    cardCVVNumber?.invalid &&
                                      (cardCVVNumber?.touched || cardCVVNumber?.dirty)
                                    ">
                                        <div *ngIf="cardCVVNumber?.errors" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>







                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="country">

                                    <div *ngIf="
                                    country?.invalid &&
                                      (country?.touched || country?.dirty)
                                    ">
                                        <div *ngIf="country?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Town/City <span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="city">
                                    <div *ngIf="
                                    city?.invalid &&
                                      (city?.touched || city?.dirty)
                                    ">
                                        <div *ngIf="city?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="state">
                                    <div *ngIf="
                                    state?.invalid &&
                                      (state?.touched || state?.dirty)
                                    ">
                                        <div *ngIf="state?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="address">
                                    <div *ngIf="address?.invalid &&
                                    (address?.touched || address?.dirty)
                                  ">
                                        <div *ngIf="address?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Post code Zip<span class="required"> </span></label>
                                    <input type="text" class="form-control" formControlName="postal">
                                    <div *ngIf="postal?.invalid &&
                                    (postal?.touched || postal?.dirty)
                                  ">
                                        <div *ngIf="postal?.errors?.required" class="text-danger">
                                            required
                                        </div>

                                    </div>
                                </div>
                            </div>-->






                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">price</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>


                                        <td class="product-name"><a routerLink="/">{{cartItems?.type}}</a>
                                            <br>
                                            <p *ngFor="let q of cartItems?.details">
                                                Q: {{q.q}}
                                                <br>
                                                A: {{q.a}}
                                            </p>
                                            <br>
                                            <p *ngFor="let maid of cartItems?.maid">
                                                Maid Name: {{maid?.name}}, {{maid?.nationality}}
                                            </p>
                                        </td>
                                        <td class="product-price"><span class="unit-amount"></span>
                                            <span >
                                                {{ cartItems.amount }}
                                            </span>
                                          

                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <div class="payment-box">
                            <div class="payment-method">
                                <!--  <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <label for="direct-bank-transfer">Direct Bank Transfer</label> Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have
                                    cleared in our account.
                                </p>
                                <p>
                                    <input type="radio" id="paypal" name="radio-group">
                                    <label for="paypal">PayPal</label>
                                </p>
                                <p>
                                    <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Cash on Delivery</label>
                                </p>-->
                            </div>
                            <div *ngIf="done && msg != null" class="alert alert-success">
                                <span>{{ msg }}</span>
                            </div>
                            <div *ngIf="!done && msg != null" class="alert alert-danger">
                                <span>{{ msg }}</span>
                            </div>

                            <div class="cart-buttons">
                                <div class="row align-items-center">
                                    <div class="col-lg-7 col-sm-7 col-md-7">
                                        <span *ngIf="amountAfterPromo">Total After Promo: {{amountAfterPromo}}</span>
                                        <div class="shopping-coupon-code">
                                            <input type="text" class="form-control" placeholder="Promo code if you have" name="coupon-code" id="coupon-code" type="text" class="form-control" formControlName="coupon" />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="cartItems" type="submit" class="default-btn order-btn">Place
                                Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>