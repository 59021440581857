import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthenticationService } from "src/app/services/auth.service";
import { CookieService } from "src/app/services/cookie.service";
import { DefultService } from "src/app/services/defult/defult.service";
import { environment } from "src/environments/environment.prod";

@Component({
    selector: "app-affiliate",
    templateUrl: "./affiliate.component.html",
    styleUrls: ["./affiliate.component.scss"],
})
export class AffiliateComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    user: any = null;

    Home = null;
    constructor(
        private router: ActivatedRoute,
        private routerNavigation: Router,
        private _defult: DefultService,
        private _http: HttpClient,
        private cook: CookieService,
        private auth: AuthenticationService
    ) {
        this.user = this.auth.currentUser();
     
    }
    allNotifications:any
    ngOnInit(): void {
   this._http.get<any>(environment.BaseUrl + `/notifications`).subscribe(
    (res)=>{
console.log(res)
this.allNotifications = res.data;
    }
   )
    }

}
