import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private _http : HttpClient) { }
  getAllBlogs(limit?:any,page?:any){
    return this._http.post<any>(environment.BaseUrl+`/blog/getAll`,{page,limit});
  }
   getOneBlogs(id?:any){
    return this._http.post<any>(environment.BaseUrl+`/blog/getOne`,{id});
  }
}
