<footer class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <img src="assets/img/lgo.png" alt="image" />
                    <p>
                        Halan Cleaning Service, has been
                        providing different kinds of cleaning services
                        in Dubai for almost 6 years now. Our staff
                        knows how to get the job done, and we often
                        exceed the expectations of our clients so
                        that they will continue with us by having a
                        good services.                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <h3>Popular Courses</h3> -->

                    <ul class="footer-quick-links">
                        <!-- <li><a routerLink="/">Support Center</a></li>
                        <li><a routerLink="/">Popular Campaign</a></li>
                        <li><a routerLink="/">Customer Support</a></li>
                        <li><a routerLink="/">Copyright</a></li>
                        <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 *ngIf="lang == 'en'">Quick Links</h3>
                    <h3 *ngIf="lang == 'ar'">روابط سريعة</h3>
                    <ul class="footer-quick-links">
                        <li>
                            <a routerLink="/about">About Us</a>
                        </li>
                        <li>
                            <a routerLink="/">Maids</a>
                        </li>
                        <li>
                            <a routerLink="/">Offers</a>
                        </li>
                        <li>
                            <a routerLink="/">Promo Codes</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 *ngIf="lang == 'en'">Our Information</h3>
                    <h3 *ngIf="lang == 'ar'">معلومات عنا</h3>

                    <ul
                        class="footer-quick-links address-link"
                        style="direction: ltr"
                    >
                        <li>
                            <a href="#"
                                ><i class="fa fa-map-marker"></i>
                                OFFICE NO. 1301 <br>
                                OPAL TOWER, BUSINESS BAY, DUBAI UAE</a
                            >
                        </li>
                        <li>
                            <a [href]="'tel:' + phone"
                                ><i class="fa fa-phone"></i> +971 4 3434 737</a
                            >
                        </li>
                        <li>
                            <a [href]="'tel:' + phone"
                                ><i class="fa fa-phone"></i> +971 52 192 9477</a
                            >
                        </li>
                    </ul>

                    <ul class="social-list">
                        <li style="margin-right: 20px">
                            <a
                                href="https://www.facebook.com/HalanCleaningService"
                                class="facebook"
                                target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <!--<li>
                            <a href="#" class="twitter" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="linkedin" target="_blank"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li>-->
                        <li>
                            <a
                                href="https://instagram.com/halancleaningservice"
                                class="instagram"
                                target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        <i class="far fa-copyright"></i>2023 Halan All Rights
                        Reserved by
                        <a href="" target="_blank"
                            ><strong>Halan Co.</strong></a
                        >
                    </p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li>
                            <a routerLink="/privacy">Terms and Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<!-- <div class="sidebar-modal">
    <div
        class="modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo/logo.png" alt="Logo" />
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Ervy</h3>
                        <img src="assets/img/about.jpg" alt="" />
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nemo dolore saepe exercitationem alias unde,
                            blanditiis non error
                        </p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>121 King St, VIC 3000, Australia</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>hello@ervy.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+098-123-23455</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="fab fa-facebook-f"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="fab fa-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="fab fa-linkedin-in"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="fab fa-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
