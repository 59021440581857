import { Injectable } from '@angular/core';
import { CookieService } from '../cookie.service';

@Injectable({
  providedIn: 'root'
})
export class DefultService {
  constructor(private cook: CookieService) {}
  setDefult(langId?:any,currancyId?:any) {
    this.cook.setCookie(
      'defult',
      JSON.stringify({ null: true, langId: langId }),
      1
    );
  }
  getDefult() {
    if (this.cook.getCookie('defult')) {
      return JSON.parse(this.cook.getCookie('defult'));
    } else {
      return { null: false, langId: 1 };
    }
  }
}
