import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "./cookie.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class StudentService {
    constructor(private _http: HttpClient,private cook: CookieService
        ) {}
        users: any;

    loginStudent(email: string, password: string) {
        return this._http.post<any>(environment.BaseUrl + "/login", {
            username: email,
            password: password,
        }).pipe(
            map((data) => {
              // login successful if there's a jwt token in the response
              if (data) {
                this.users = data.data.user;
                console.log("login", this.users);
                // store user details and jwt in cookie
                this.cook.setCookie(
                  "currentUser",
                  JSON.stringify(this.users),
                  15
                );
                this.cook.setCookie("token", data.data.token, 30);
                //console.log("token", data.data.token);
              }
              return data;
            })
          );
    }
    registerStudent(
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        phone:string
    ) {
        return this._http.post<any>(
            environment.BaseUrl + "/signup",
            {
        
                name:firstName+ " " + lastName,
                email: email,
                password: password,
                phone:phone
            }
        ).pipe(
            map((data) => {
              // login successful if there's a jwt token in the response
              if (data) {
                this.users = data.data.user;
                console.log("login", this.users);
                // store user details and jwt in cookie
                this.cook.setCookie(
                  "currentUser",
                  JSON.stringify(this.users),
                  15
                );
                this.cook.setCookie("token", data.data.token, 30);
                //console.log("token", data.data.token);
              }
              return data;
            })
          );
    }
    updateProfile(
        id?: any,
        firstName?: any,
        photo?: any,
        email?: any,
        phoneNumber?: any
    ) {
        let formData = new FormData();
        formData.append("photo", photo);
        formData.append("email", email);
        formData.append("phone", phoneNumber);
        formData.append("name", firstName);
        console.log(photo)
        return this._http.patch<any>(
            environment.BaseUrl + `/profile`,
            formData
        ).pipe(
            map((data) => {
              // login successful if there's a jwt token in the response
              if (data) {
                this.users = data.data;
                console.log("login", this.users);
                // store user details and jwt in cookie
                this.cook.setCookie(
                  "currentUser",
                  JSON.stringify(this.users),
                  15
                );
             
              }
              return data;
            })
          );
    }

    resetPassword(token, pass) {
        return this._http.post<any>(
            environment.BaseUrl + "/reset-password",
            {
               code: token,
                password:pass,
            }
        );
    }

    forgetPassword(email, base_url) {
        return this._http.post<any>(
            environment.BaseUrl + "/forget-password",
            {
                email
             
            }
        );
    }

    changePassword(oldpassword, newpassword, email) {
        return this._http.patch<any>(
            environment.BaseUrl + "/profile/password",
            { oldpassword, newpassword }
        );
    }

    uploadProfilePhoto(image, email) {
        let formData = new FormData();
        formData.append("image", image);
        formData.append("email", email);
        return this._http.post<any>(
            environment.BaseUrl + "/student/uploadProfileImage",
            formData
        );
    }

    getMyCourses(
        userId?: any,
        sortType?: any,
        sortCol?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }
        return this._http.post<any>(
            environment.BaseUrl + `/student/getMyCourses/${userId}`,
            {
                sortType: sortType,
                sortCol: sortCol,
                start: start,
                per_page: per_page,
            }
        );
    }

    getMyBooks(
        userId?: any,
        sortType?: any,
        sortCol?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }
        return this._http.post<any>(
            environment.BaseUrl + `/student/getMyBooks/${userId}`,
            {
                sortType: sortType,
                sortCol: sortCol,
                start: start,
                per_page: per_page,
            }
        );
    }
}
