import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ExamsService {

  constructor(private _http: HttpClient) {

  }


  //---------------------------to student -------------------//

  getExam(courseId?: any) {
    return this._http.post<any>(
        environment.BaseUrl + `/exam/getExamByCourseId`,{ courseId: courseId}
    );
   }

   getExamById(examId?: any) {
    return this._http.post<any>(
        environment.BaseUrl + `/exam/getExamById`,{examId}
    );
   }

   saveUserResultForCourseExam(course_id,exam_id,student_id,result,points,finalResult,certificate_id,badge_id){
    return this._http.post<any>(
        environment.BaseUrl + `/exam-student/saveUserResult`,{course_id,exam_id,student_id,result,points,finalResult,certificate_id,type:'course',badge_id}
    );
   }

   saveUserResultForCourseExamDirect(course_id,student_id,points,certificate_id,badge_id){
    return this._http.post<any>(
        environment.BaseUrl + `/exam-student/saveUserResultDirect`,{course_id,student_id,points,certificate_id,type:'course',badge_id}
    );
   }



   checkUserGetExam(courseId,examId,studentId){
    return this._http.post<any>(
        environment.BaseUrl + `/exam-student/checkUserGetExam`,{courseId,examId,studentId,type:'course'}
    );
   }





}
