import { Component, OnInit } from "@angular/core";
import { BlogService } from "src/app/services/blog.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "src/app/services/auth.service";

@Component({
    selector: "app-blog",
    templateUrl: "./blog.component.html",
    styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
    blogs: any = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    baseUrl: string = environment.BaseUrl;
    lang = "en";
    Blog: any;
    Home: any;
    Admin: any;

    constructor(
        private _blog: BlogService,
        private _defult: DefaultService,
        public translate: TranslateService,
        private _http : HttpClient,
        private auth :AuthenticationService

    ) {
        this.auth.NotifyNumber()
        this.auth.chechNotifyNumber()
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
this. getPromos()
        // this.getData();
    }

    getLabels() {
        this.translate.get(["Blog", "Home", "Admin"]).subscribe((res) => {
            this.Blog = res.Blog;
            this.Home = res.Home;
            this.Admin = res.Admin;
        });
    }

    changeOffset() {
        this.getData();
    }

    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    getData() {
        this._blog.getAllBlogs(this.limit, this.offset).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.blogs = res.data;
                    var count = res.count;
                    this.total_pages = Math.ceil(count / this.limit);
                    this.number = Array.from(
                        { length: this.total_pages },
                        (v, k) => k + 1
                    );
                }
            },
            (err) => {}
        );
    }
    promos:any=[]

    getPromos(){
        this._http.get<any>(environment.BaseUrl + `/promos`).subscribe((res) => {
            console.log(res);
            this.promos = res.data.reverse();
         
      
          });
    }
}
