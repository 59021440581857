import { Component, OnInit } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import { CourseService } from "src/app/services/course.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { OrderService } from "src/app/services/order.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { HttpClient } from "@angular/common/http";
declare var $: any;
import { formatDate } from '@angular/common';

@Component({
    selector: "app-courses-list",
    templateUrl: "./courses-list.component.html",
    styleUrls: ["./courses-list.component.scss"],
})
export class CoursesListComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },

        {
            id: 5,
            value: "rate",
            name: "Sort by average rating",
            type: "DESC",
        },

        {
            id: 6,
            value: "no_student",
            name: "Sort by popularity",
            type: "DESC",
        },
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    courses = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    CoursesList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;
    user: any = null;
    email2: any = null;
    name = null;

    constructor(
        private auth: AuthenticationService,
        private _course: CourseService,
        private _cart: CartService,
        private _defult: DefaultService,
        public translate: TranslateService,
        private _order: OrderService,
        private routerNavigation: Router,
        private _http : HttpClient
    ) { this.auth.NotifyNumber()
     
     
    }
    message:any = null
    days: Date[] = [];
    selectedDay: Date | null = null;
    selectedAddress:any | null = null;
    addresses:any[]=[];
    getAllMyAdd(){
      this._http.get<any>(environment.BaseUrl + `/address`).subscribe(
        (res) => {
        console.log(res.data, "Adresses");
        if(res.data.length >= 1){
          this.addresses = res.data
        }else{
          this.addresses = []
        }
    
     }
        ,(err:any)=>{
            this.msg = true;
            this.msgText = "No Data Were Found!";
        }
        
        );
    }
    ngOnInit(): void {
        if (this.auth.currentUser()) {
       this.getAllMyAdd()
            this.user = this.auth.currentUser();
            console.log("USSE",this.user)
            this.email2 = this.user.email;
            this.name = this.user.name;
            this.getLabels();
            this.getPrice()
            this.generateDays();
        }else{
          this.message = "You have to login!"
        }
 
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
 
    }

    generateDays(): void {
        const today = new Date();
        for (let i = 0; i <= 20; i++) {
          const day = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
          this.days.push(day);
        }
      }
      addnewAddress(name,street,city,postal,country,floarNo){
        console.log(name.value)
        this._http.post<any>(environment.BaseUrl + `/add-address`,{
          "name":name.value,
          "street":street.value,
          "city":city.value,
          "postal":postal.value,
          "country":country.value,
          "floarNo":floarNo.value,

        }).subscribe(
          (res) => {
          console.log(res);
          name.value = ''
          street.value = ''
          city.value=''
       postal.value=''
        country.value=''
         floarNo.value=''
          this.getAllMyAdd()
      
       }
          ,(err:any)=>{
              this.msg = true;
              this.msgText = "No Data Were Found!";
          }
          
          );

      }

    selectDay(day: Date): void {
        this.selectedDay = day;
        this.getFreeMaids("hourly")
      }
      locations:string[] = ["Inside Country" , "Outside Country"]
    numbers: number[] = [1, 2, 3, 4, 5];
    hours:number[] = [ 2, 3, 4, 5,6,7];
    mats:string[]=["Yes","No"]
    daysWeek:number[] = [1, 2, 3, 4, 5,6,7];
    Months:number[] = [1, 2, 3, 4, 5,6,7];
    paymentMethod: string[] = ["cash" , "credit card"]
    selectedNumber: number | null = null;
    selectedMonths: number | null = null;
    selecteddaysWeek: number | null = null;
    selectedHour: number | null = null;
    selectedMats: string | null=null;
    selectedpaymentMethod :string | null = null;

    selectNumber(number: number): void {
      this.selectedNumber = number;
      this.selectedMaid = []
      this.nationTotalPrice()
    }

    selectMonths(number: number): void {
      this.selectedMonths = number;
  
    }

    selectDayWeek(number: number): void {
      this.selecteddaysWeek = number;
      this.total = this.total + (this.services.day * number)
    }
    startHour = 8;
    endHour = 19; // 24-hour format
  
    hourRanges: string[] = [];

    generateHourRanges() {
      this.hourRanges = [];
      for (let i = this.startHour; i <= this.endHour - this.selectedHour + 1; i++) {
        this.hourRanges.push(`${i}:00 - ${i + this.selectedHour}:00`);
      }
    }

    selectedHours: string;

  selectHours(hour: string) {
    this.selectedHours = hour;
  }

    //selectedHour
    selectHour(number: number): void {
        this.selectedHour = number;
        this.generateHourRanges()
     if(!this.selecteddaysWeek)   this.total = this.total + (this.services.hour * number)
      }
      addMat:any = false
    selectMats(number: string): void {
        this.selectedMats = number;
        if(number == "Yes") {
            this.total = this.total + this.services.matrial
            this.addMat = true
        }
        if(number == "No" &&   this.addMat)this.total = this.total - this.services.matrial
      }

     
      selectpaymentMethod(number: string): void {
        this.selectedpaymentMethod = number;
      }
      selectedLocation: any;

      selectLocation(number: string): void {
        this.selectedLocation = number;
        this.getAllOnApp()
      }

      //Get all maids************************
      //FILTER Maids************************

      onApp: any = [];
      outSide: any=[]
      inSide: any=[]
      getAllOnApp() {
        this._http.get<any>(environment.BaseUrl + `/app-maids`).subscribe(
          (res) => {
          console.log(res);
          this.onApp = res.data; 
          this.onApp =   this.onApp.filter(maid => maid.type === 'yearly')
         if(this.selectedLocation == "Inside Country") this.onApp = this.onApp.filter(maid => maid.inCountry == true);
         if(this.selectedLocation == "Outside Country") this.onApp= this.onApp.filter(maid => maid.inCountry == false);
         console.log("Filtered", this.onApp);
        }
          ,(err:any)=>{
              this.msg = true;
              this.msgText = "No Data Were Found!";
          }
          
          );
      }


      selectedMaidC:number
      selectMaidC(maidID: any): void { 
              this.selectedMaidC = maidID;
             let maid =  this.onApp.filter(person => person.id === this.selectedMaidC)
             console.log(maid)
             this.total = maid[0].salary
      
      }

      /******************************************************** */
      selectNation(number: string): void {
        this.selectednations = number;
        this.nationTotalPrice()
        if(this.nation.length > 0)this.filterPeople();
      }
      nationTotalPrice(){
     if(this.selectednations){
        let nationPrice = this.services.nationality.filter(person => person.name === this.selectednations);
        console.log("Nation",nationPrice)
        this.total = this.total + (nationPrice[0].price * this.selectedNumber)
     }
      }
      formatDate(date: Date): string {
        return formatDate(date, 'M/d/yyyy', 'en-US');
      }
      maids:any[];
      selectedMaid:any[]=[]
      selectednations:any | null = null
nation:any[]
filteredPeople: any[]
      getFreeMaids(type:any){
            let obj = {
                       type:type
                  }
            if(type == "hourly") obj["day"] =this.formatDate(this.selectedDay)
        this._http.post<any>(environment.BaseUrl + `/free-maid`,obj).subscribe(
            (res) => {
            console.log("FREE",res);
            this.maids = res.data.free
            this.filteredPeople =  res.data.free
            this.nation = res.data.nationalities
         }
            ,(err:any)=>{
                this.msg = true;
                this.msgText = "No Data Were Found!";
            }
            
            );
      }
      selectMaid(maidID: any): void {
        if (this.selectedMaid.length < this.selectedNumber) {
       
            this.selectedMaid.push(maidID);
          
        
          
        }else{
          if(this.selectedMaid.includes(maidID))
            {
              this.selectedMaid = this.selectedMaid.filter(item => item !== maidID);
            }
        }
     
      }

      selectAddress(maidID: any): void {
       
        this.selectedAddress = maidID;
      }

      filterPeople(): void {
        if (this.selectednations) {
          this.filteredPeople = this.maids.filter(person => person.nationality === this.selectednations);
        } else {
          this.filteredPeople = [];
        }
      }
    getLabels() {
        this.translate
            .get([
                "Home",
                "CoursesList",
                "Showing",
                "results",
                "of",
                "AddToCart",
                "RemovefromCart",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.CoursesList = res.CoursesList;
                this.Showing = res.Showing;
                this.results = res.results;
                this.of = res.of;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
            });
    }
    total:number = 0;
    services:any
    nationalities:any[]=[]
getPrice(){
    //services-price
    this._http.get<any>(environment.BaseUrl + `/services-price/2`).subscribe(
        (res) => {
        console.log(res);
      this.services = res.data
      this.nationalities =  this.services.nationality
     }
        ,(err:any)=>{
            this.msg = true;
            this.msgText = "No Data Were Found!";
        }
        
        ); 
}

reservationJson : any = {
  "type":"Single Reservation",
  "details":[{
      "q":"How many professionals do you need?",
      "a":"1"
  },
  {
      "q":"How many hours do you need?",
      "a":"2"
  },
    {
      "q":"when would you like your service?",
      "a":"fri 2/3/2023"
  },
    {
      "q":"How many hours do you need?",
      "a":"2"
  },
  {
      "q":"Do you require cleaning matrials?",
      "a":"No" 
  },
    {
      "q":"Which Nationality Do you prefere?",
      "a":"Flibino" 
  }
  ],
  "maid":1,
  "address":1,
  "date":"Fri 02/03/2023",
  "paymentMethod":"cash",
  "amount":758,
  

}

orderNow(){

  this._http.post<any>(environment.BaseUrl + `/reserve`,{

"type":"Single Reservation",
  "details":[{
      "q":"How many professionals do you need?",
      "a":this.selectedNumber
  },
  {
      "q":"How many hours do you need?",
      "a":this.selectedHour
  },
    {
      "q":"when would you like your service?",
      "a":this.formatDate(this.selectedDay)
  },
  {
      "q":"Do you require cleaning matrials?",
      "a":this.selectedMats
  },
    {
      "q":"Which Nationality Do you prefere?",
      "a":this.selectednations
  }
  ],
  "maid":this.selectedMaid,
  "address":this.selectedAddress,
  "date":this.formatDate(this.selectedDay),
  "paymentMethod":this.selectedpaymentMethod,
  "amount":this.total,
  }).subscribe(
    (res) => {
    console.log(res);
    Swal.fire({
      title: "",
      text: `Your Single Reservation sent successfully`,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
  }).then((result) => {});

 }
    ,(err:any)=>{
        this.msg = true;
        this.msgText = "No Data Were Found!";
    }
    
    );

}
bookPack(){
  

  this._http.post<any>(environment.BaseUrl + `/reserve`,{

    "type":"Package Reservation",
      "details":[{
          "q":"How many professionals do you need?",
          "a":this.selectedNumber
      },
    
      {"q":" How many Months do you need your professionals to stay?",
        "a":this.selectMonths

      },
      {"q":"How many days a week do you need your professionals to stay?",
        "a":this.selecteddaysWeek

      },
      {"q":"When would you like your service?",
        "a":this.selectedHours

      },
      {
          "q":"How many hours do you need?",
          "a":this.selectedHour
      },
       
      {
          "q":"Do you require cleaning matrials?",
          "a":this.selectedMats
      },
        {
          "q":"Which Nationality Do you prefere?",
          "a":this.selectednations
      }
      ],
      
      "address":this.selectedAddress,
      "date":this.formatDate(new Date()),
      "paymentMethod":this.selectedpaymentMethod,
      "amount":this.total,
      }).subscribe(
        (res) => {
        console.log(res);
        Swal.fire({
          title: "",
          text: `Your Package Reservation sent successfully`,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
      }).then((result) => {});
    
     }
        ,(err:any)=>{
            this.msg = true;
            this.msgText = "No Data Were Found!";
        }
        
        );
}

contract(notes){


  
  this._http.post<any>(environment.BaseUrl + `/reserve`,{

    "type":"Contract Reservation",
    "maid":this.selectedMaidC,      
    "note":notes.value,
      "address":this.selectedAddress,
      "date":this.formatDate(new Date()),
      "paymentMethod":this.selectedpaymentMethod,
      "amount":this.total,
      }).subscribe(
        (res) => {
        console.log(res);
        Swal.fire({
          title: "",
          text: `Your Contract Reservation sent successfully`,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
      }).then((result) => {});
    
     }
        ,(err:any)=>{
            this.msg = true;
            this.msgText = "No Data Were Found!";
        }
        
        );

}
   
}
