import { Component, OnInit } from "@angular/core";
import { BlogService } from "src/app/services/blog.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-blog-details",
    templateUrl: "./blog-details.component.html",
    styleUrls: ["./blog-details.component.scss"],
})
export class BlogDetailsComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    blogId: any;
    blog: any = {};
    lang = "en";
    BlogDetails: any;
    Home: any;
    PostedOn: any;
    PostedBy: any;
    Admin: any;
    constructor(
        private _blog: BlogService,
        private router: ActivatedRoute,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        this.blogId = this.router.snapshot.paramMap.get("id");
        this.getBlog();
    }

    getLabels() {
        this.translate
            .get(["BlogDetails", "Home", "PostedOn", "Author", "Admin"])
            .subscribe((res) => {
                this.BlogDetails = res.BlogDetails;
                this.Home = res.Home;
                this.PostedOn = res.PostedOn;
                this.PostedBy = res.Author;
                this.Admin = res.Admin;
            });
    }
    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    getBlog() {
        this._blog.getOneBlogs(this.blogId).subscribe(
            (res) => {
                this.blog = res.data;
                //console.log(this.blog);
            },
            (err) => {
                // console.log(err)
            }
        );
    }
}
