import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { DefaultService } from "../../../services/default.service";
import { TranslateService } from "@ngx-translate/core";
import { LogsService } from "../../../services/logs.service";
import { AuthenticationService } from "../../../services/auth.service";
import { StudentService } from "../../../services/student.service";
import { CourseService } from "../../../services/course.service";
import { LessonService } from "../../../services/lesson.service";
import { QuizConfig } from "src/app/models/quiz-config";
import { ExamsService } from "../../../services/exams.service";
import { ToastrService } from "ngx-toastr";

import { Question } from "src/app/models/question";
import { Option } from "src/app/models/option";
import { CertificationsService } from "src/app/services/certifications.service";

declare var $: any;

@Component({
    selector: "this-video-stream",
    templateUrl: "./show-my-courses.component.html",
    styleUrls: ["./show-my-courses.component.scss"],
})
export class ShowMyCoursesComponent implements OnInit {
    key: any[] = [];
    VideoStream: any;
    viewsText: any;
    castText: any;
    category: any;
    about: any;
    Tags: any;
    UpNext: any;
    Home: any;
    CourseContent: any;
    CourseDetails: any;
    courseId: string = "-1";
    totallessons: any = 0;
    lessons: any = [];
    course: {};
    section: any = [];
    baseUrl: string = environment.BaseUrl;
    lang = "en";
    currentLessonId = null;
    currentTime = 0;
    currentLessonIndex: any = 0;
    currentSectionIndex: any = 0;
    video = null;
    type = null;
    logs: any = [];
    points: any = 0;
    current_question_no = 0;
    all_question = 0;
    questions: any = [];
    score = 0;
    quizFinish = false;
    arr: any = [];
    done = false;
    user: any = {};

    mode = "quiz";
    pager = {
        index: 0,
        size: 1,
        count: 1,
    };
    timer: any = null;
    startTime: Date;
    endTime: Date;
    ellapsedTime = "00:00";
    duration = "";
    examId = null;
    notShowExam = false;
    finished = false;
    yourResult = 0;
    certificateId = null;
    repeatExam = false;
    config: QuizConfig = {
        allowBack: true,
        allowReview: true,
        autoMove: false, // if true, it will move to next question automatically when answered.
        duration: 0, // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
        pageSize: 1,
        requiredAll: false, // indicates if you must answer all the questions before submitting.
        richText: false,
        shuffleQuestions: false,
        shuffleOptions: false,
        showClock: false,
        showPager: true,
        theme: "none",
    };
    correct = [];
    opened = false;
    plan_id = null;
    badgeId = null;
    examExist = false;
    getCertificateFlag = false;
    modal = false;
    certificateImg: any;
    valid: any;
    nameincert: any;
    numberinCert: any;
    titleincert: any;
    instructorId: any;
    instructorCert: any;

    yougotthecoursecertificate: any;
    Ifyoufinishthiscourse: any;
    GetCertificate: any;
    SeeIt: any;

    constructor(
        private router: ActivatedRoute,
        private _exam: ExamsService,
        private _course: CourseService,
        private _certificate: CertificationsService,
        private _lesson: LessonService,
        public translate: TranslateService,
        public _log: LogsService,
        private _default: DefaultService,
        private auth: AuthenticationService,
        private _router: Router,
        private student: StudentService //private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        //check if user exist
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
        } else {
            this._router.navigateByUrl("/");
        }

        //get langauge

        if (this._default.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }

        this.getLabels();

        if (this.courseId == "-1") {
            this.courseId = this.router.snapshot.paramMap.get("id");
        }

        this.getCourseInfo();
        this.getLessons();
        // this.getLogs();
    }

    getCourseInfo() {
        this._course.getOneCourse(this.courseId).subscribe((res) => {
            if (res.apiStatus) {
                this.course = res.data[0];
                this.points = res.data[0].points;
                this.certificateId = res.data[0].certificate_id;
                this.checkCertificate(this.certificateId);
            }
        });
    }
    checkCertificate(certificate) {
        this._certificate
            .checkCertificate(this.user.id, certificate, this.courseId)
            .subscribe((res) => {
                if (res.apiStatus) {
                    this.getCertificateFlag = true;
                }
            });
    }

    openCertificateClickOn2() {
        this._certificate
            .getOneCertificate(this.certificateId, this.user.id, this.courseId)
            .subscribe((res) => {
                if (res.error == 0) {
                    this.certificateImg =
                        this.baseUrl + "/" + res.data[0].certificate;

                    this.valid = res.data[0].validthru;
                    this.numberinCert = res.data[0].number;
                    this.nameincert = res.data[0].student;
                    this.titleincert = res.data[0].title;
                    this.instructorCert = res.data[0].instructor;
                    this.modal = true;
                    this.getCertificateFlag = true;
                }
            });
    }

    closeModal() {
        this.modal = false;
    }

    play(video: any) {
        this.type = 0;
        let source = document.getElementById("pdf");
        source.setAttribute("src", video);
    }
    getLessons() {
        this._lesson.getAllLessons(this.courseId).subscribe((res2) => {
            this.lessons = res2.data;

            this.lessons.forEach((e) => {
                let num = -1;
                for (var i = 0; i < this.section.length; i++) {
                    if (this.section[i].en_name == e.en_section) {
                        num = i;
                    }
                }

                if (num == -1) {
                    this.section.push({
                        ar_name: e.ar_section,
                        en_name: e.en_section,
                        lessons: [e],
                    });
                } else {
                    this.section[num].lessons.push(e);
                }

                if (this.section.length != 0) {
                    this.currentLessonId = this.lessons[0].lessons_id;
                    if (this.type == 0) {
                        this.video = this.lessons[0].video;
                    }
                    if (this.type == 1) {
                        this.video = this.baseUrl + "/" + this.lessons[0].video;
                    }
                    this.type = this.lessons[0].type;

                    this.init(this.video, this.lessons[0].lessons_id);
                }
            });
            this.getExam();
        });
    }

    init(video, lessId) {
        var source = null;

        if (this.type == 0) {
            source = document.getElementById("pdf");
            source.setAttribute("src", video);
        } else if (this.type == 1) {
            source = document.getElementById("pdf");
            source.setAttribute("src", this.baseUrl + "/" + video);
        } else {
            // showQuestion(lessId);
        }
    }

    getDate(date) {
        let d = new Date(date);

        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth() + 1; //monthes
        let year = d.getUTCFullYear(); //year

        return mon + " / " + day + " / " + year;
    }

    getLabels() {
        this.translate
            .get([
                "CourseContent",
                "CourseDetails",
                "Home",
                "yougotthecoursecertificate",
                "SeeIt",
                "GetCertificate",
                "Ifyoufinishthiscourse",
            ])
            .subscribe((res) => {
                //  console.log(res)
                this.Home = res.Home;
                this.CourseDetails = res.CourseDetails;
                this.CourseContent = res.CourseContent;

                this.yougotthecoursecertificate =
                    res.yougotthecoursecertificate;
                this.Ifyoufinishthiscourse = res.Ifyoufinishthiscourse;
                this.GetCertificate = res.GetCertificate;
                this.SeeIt = res.SeeIt;
            });
    }

    getActive(id) {
        if (this.currentLessonId == id) {
            return true;
        } else {
            return false;
        }
    }

    //------------------------ exams ----------------------

    repeatExamFun() {
        this.mode = "quiz";
        this.startTime = new Date();
        this.ellapsedTime = "00:00";
        this.notShowExam = false;
        this.repeatExam = false;
        this.finished = false;

        let source: any = document.getElementById("pdf");
        source.setAttribute("src", null);
        this.type = 2;
        if (this.notShowExam == false && this.finished == false) {
            this.timer = setInterval(() => {
                this.tick();
            }, 1000);
        } else {
        }
    }

    checkifUserTakeExam(examId) {
        this._exam
            .checkUserGetExam(this.courseId, examId, this.user.id)
            .subscribe(
                (res) => {
                    this.repeatExam = res.repeatExam;
                    this.notShowExam = !res.examOpen;
                    if (res.data.length > 0) {
                        this.yourResult = res.data[res.data.length - 1].result;
                    }
                },
                (err) => {
                    // console.log(err)
                    this.notShowExam = false;
                    this.repeatExam = false;
                    this.yourResult = 0;
                }
            );
    }

    getFinish(lessonId) {
        let lesson = this.logs.find((e) => e.lesson_id == lessonId);
        if (lesson) {
            if (lesson.finish == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    get filteredQuestions() {
        return this.questions
            ? this.questions.slice(
                  this.pager.index,
                  this.pager.index + this.pager.size
              )
            : [];
    }

    isAnswered(question: Question) {
        return question.options.find((x) => x.selected)
            ? "Answered"
            : "Not Answered";
    }

    isCorrect(question: Question) {
        return question.options.every((x) => x.selected === x.isAnswer)
            ? "correct"
            : "wrong";
    }

    getExam() {
        this._exam.getExam(this.courseId).subscribe((res2) => {
            if (res2.error == 0) {
                if (res2.data.length > 0) {
                    this.examExist = true;

                    let questions: any = JSON.parse(res2.data[0].questions);

                    let num = questions.length;
                    if (res2.data[0].no_questions <= questions.length) {
                        num = res2.data[0].no_questions;
                    }

                    const arr = [];
                    for (let i = 0; i < num; ) {
                        const random = Math.floor(
                            Math.random() * questions.length
                        );
                        if (arr.indexOf(questions[random]) !== -1) {
                            continue;
                        }
                        arr.push(questions[random]);
                        i++;
                    }

                    this.questions = arr;
                    this.pager.count = this.questions.length;

                    this.config.duration = parseInt(res2.data[0].duration) * 60;
                    let duration = parseInt(res2.data[0].duration) * 60;
                    this.duration = this.parseTime(this.config.duration);
                    this.examId = res2.data[0].id;

                    this.checkifUserTakeExam(this.examId);

                    this.section.push({
                        ar_name: "Exam",
                        en_name: "Exam",
                        lessons: [
                            {
                                ar_des: res2.data[0].des,
                                ar_name: res2.data[0].name,
                                ar_section: "Exam",
                                duration: res2.data[0].duration + "min",
                                en_des: res2.data[0].des,
                                en_name: res2.data[0].name,
                                en_section: "Exam",
                                image: "",
                                lessons_id: 1,
                                type: 2,
                                video: "",
                            },
                        ],
                    });
                }
            }
        });
    }

    tick() {
        const now = new Date();
        const diff = (now.getTime() - this.startTime.getTime()) / 1000;
        if (diff >= this.config.duration && this.finished == false) {
            this.onSubmit();
        }
        this.ellapsedTime = this.parseTime(diff);
    }

    parseTime(totalSeconds: number) {
        let mins: string | number = Math.floor(totalSeconds / 60);
        let secs: string | number = Math.round(totalSeconds % 60);
        mins = (mins < 10 ? "0" : "") + mins;
        secs = (secs < 10 ? "0" : "") + secs;
        return `${mins}:${secs}`;
    }

    onSelect(question: Question, option: Option) {
        let app = this;

        question.options.forEach((x) => {
            if (x.id !== option.id) {
                x.selected = false;
            }
        });
        question.options.forEach((x) => {
            if (x.id == option.id) {
                if (option.isAnswer) {
                    let arr = app.correct.filter((e) => e == question.id);
                    if (arr.length == 0) {
                        app.correct.push(question.id);
                    }
                } else {
                    let arr = app.correct.filter((e) => e == question.id);
                    if (arr.length > 0) {
                        app.correct.splice(app.correct.indexOf(question.id), 1);
                    }
                }
            }
        });

        if (this.config.autoMove) {
            this.goTo(this.pager.index + 1);
        }
    }

    goTo(index: number) {
        if (index >= 0 && index < this.pager.count) {
            this.pager.index = index;
            this.mode = "quiz";
        }
    }

    openExam() {
        let source: any = document.getElementById("pdf");
        source.setAttribute("src", null);

        this.type = 2;
        if (
            this.opened == false &&
            this.notShowExam == false &&
            this.finished == false
        ) {
            this.opened = true;
            this.startTime = new Date();
            this.ellapsedTime = "00:00";
            this.timer = setInterval(() => {
                this.tick();
            }, 1000);
        } else {
        }
    }

    onSubmit() {
        // let answers = [];
        // this.questions.forEach(x => answers.push({ 'questionId': x.id, 'answered': x.answered }));
        // Post your data to the server here. answers contains the questionId and the users' answer.

        this.mode = "result";
        let result = (this.correct.length / this.questions.length) * 100;

        this._exam
            .saveUserResultForCourseExam(
                this.courseId,
                this.examId,
                this.user.id,
                this.correct.length,
                this.points,
                result,
                this.certificateId,
                this.badgeId
            )
            .subscribe(
                (res) => {
                    if (res.apiStatus) {
                        this.finished = true;

                        /*  this.toastr.success(
                                "Congratulation",
                                "you finished it"
                            );*/
                    }
                },
                (err) => {
                    //console.log(err);
                    // this.toastr.error("error", "Something went wrong");
                }
            );
    }

    getCertificate() {
        this._exam
            .saveUserResultForCourseExamDirect(
                this.courseId,
                this.user.id,
                this.points,
                this.certificateId,
                null
            )
            .subscribe(
                (res) => {
                    if (res.apiStatus) {
                        this.examExist = false;
                        this.getCertificateFlag = true;

                        /*  this.toastr.success(
                        "Congratulation",
                        "you finished it"
                    );*/
                    }
                },
                (err) => {
                    //console.log(err);
                    // this.toastr.error("error", "Something went wrong");
                }
            );
    }
}
