<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">



            <div class="row text-center alert alert-success" *ngIf="error==false">
                {{msg}}
            </div>
            <div class="row text-center alert alert-danger" *ngIf="error==true">
                {{msg}}
            </div>


            <form id="formLogin">
                <div class="row">

                    <div class="col-lg-12 ">
                        <div class="form-group ">
                            <input type="password" class="form-control" placeholder="Old Password" #oldpass id='oldpass' />
                            <a id="error3" style="color: red "></a>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group ">
                            <input type="password" class="form-control " placeholder="New Password " #pass id='pass' />
                            <a id="error" style="color: red "></a>
                        </div>
                    </div>


                    <div class="col-lg-12 ">
                        <div class="form-group ">
                            <input type="password" class="form-control " placeholder="Confirm Password " #conpass id='conpass' />
                            <a id="error2" style="color: red "></a>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 ">
                        <div class="form-check ">
                            <input type="checkbox " class="form-check-input " id="checkme ">
                            <label class="form-check-label " for="checkme ">Keep me Login</label>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-12 text-end ">
                        <p class="forgot-password ">
                            <a routerLink="/ ">Forgot Password?</a>
                        </p>
                    </div> -->

                    <div class="col-lg-12 ">
                        <div class="send-btn ">
                            <a (click)="confirm(oldpass,pass,conpass) " class="default-btn-one ">{{Confirm}}</a>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>