import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthenticationService } from "../services/auth.service";
import { environment } from "../../environments/environment.prod";
import { CookieService } from "./cookie.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private cook: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // const currentUser:any = this.authenticationService.currentUser();
    console.log("this.defults");
    let user: any = this.authenticationService.currentUser();

    console.log(user, this.cook.getCookie("token"));
    let Token: any = "";
    if (user) {
      console.log("this.defults");

      Token = `Bearer ${this.cook.getCookie("token")}`;
      request = request.clone({
        setHeaders: {
          Authorization: Token,
        },
      });
    }

    return next.handle(request);
  }
}
