<section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>
                            Showing {{ (offset - 1) * limit + 1 }}-{{ (offset - 1) * limit + 1 + limit }} of {{ total_pages }} results
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select>
                            <option *ngFor="let item of filterSort" [value]="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center alert alert-danger" *ngIf="msg || certifications.length == 0">
            {{ msgText }}
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0" *ngFor="let cert of certifications; let i = index">
                <div class="single-product-box" style="cursor:pointer">
                    <app-black-certificate (click)="openCertificateClickOn2(cert)" [certificate]="baseUrl + '/' + cert.certificate" [valid]="getDate(cert.validthru)" [name]="cert.student" [title]="cert.title" type="small" [instructor]="cert.instructor" [id]="'canvas' + i"
                        [cID]="'canvas' + i"></app-black-certificate>



                </div>
            </div>
        </div>

        <div id="badgeModal" class="modal" *ngIf="modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <span aria-hidden="true" style="cursor: pointer" (click)="closeModal()">×</span>
                    </div>
                    <div class="modal-body">

                        <app-black-certificate-big [certificate]="certificateImg" [valid]="getDate(valid)" [name]="nameincert" [title]="titleincert" [number]="numberinCert" type="big" [instructor]="instructorCert" [id]="'canvas'" [cID]="'canvas'"></app-black-certificate-big>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>