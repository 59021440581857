import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-black-certificate-big",
    templateUrl: "./black-certificate-big.component.html",
    styleUrls: ["./black-certificate-big.component.scss"],
})
export class BlackCertificateBigComponent implements OnInit {
    @Input() certificate: string;
    @Input() name: string;
    @Input() valid: string;
    @Input() number: string;
    @Input() title: string;
    @Input() type: string;
    @Input() instructor: string;
    @Input() cID: string;

    constructor() {}

    ngOnInit(): void {
        let id = "#" + this.cID + " canvas";

        var c: any = document.querySelector(id);
        var ctx = c.getContext("2d");

        let base_image = new Image();
        base_image.src = this.certificate;

        let app = this;
        let name = "";

        if (app.name) {
            name = app.name.replace(/^./, app.name[0].toUpperCase());
        }

        base_image.onload = function () {
            c.width = 600;
            c.height = 600;
            ctx.drawImage(base_image, 0, 0, 600, 600);
            ctx.textAlign = "left";
            ctx.fillStyle = "white";

            ctx.font = "40px emoji";
            ctx.fillText(name, 120, 400);
            ctx.font = "35px emoji";
            ctx.fillText(app.title, 160, 200);

            ctx.font = "20px emoji";
            ctx.fillStyle = "white";
            ctx.fillText(app.instructor, 70, 490);

            ctx.font = "20px emoji";
            ctx.fillStyle = "white";
            ctx.fillText(app.valid, 420, 490);
        };
    }
}
