
<app-navbar-two></app-navbar-two>
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Notifications</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>Notifications</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Title</th>
                        <th scope="col">Body</th>
                        <th scope="col">Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rev of allNotifications">
                        <th scope="row">{{rev?.obj?.date | date}}</th>
                        <td>{{rev?.title}}</td>
                        <td >
                            {{rev?.body}}
                          </td>
                        <td>{{rev?.obj?.type}}</td>
                        <td>{{rev?.obj?.amount}}</td>
                        <td>{{rev?.obj?.paymentMethod}}</td>
                        <td>
                            <a class="btn btn-warning"  routerLink="/profile">
Go Profile
                            </a>
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
        </div>
    </div>
</section>