<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ CourseDetails }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ CourseDetails }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="shop-area ptb-100">
    <div class="container">
        <div class="row py-3">
            <div class="col-lg-8 col-md-12" style="background-color: black">
                <div style="width: 100%">
                    <div style="height: 95%; min-height: 500px" id="videoStreamDiv">
                        <iframe [hidden]="type == 2" src="" id="pdf" frameBorder="0" scrolling="auto" style="width: 100%; height: 500px"></iframe>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <div [hidden]="type == 0 || type == 1" style="
                                height: 100%;
                                opacity: 1;
                                background-color: aliceblue;
                            ">
                            <div id="quiz" *ngIf="notShowExam == false">
                                <br />
                                <div *ngIf="mode == 'quiz'">
                                    <div *ngFor="
                                        let question of filteredQuestions
                                    ">
                                        <div class="badge badge-info">
                                            Question {{ pager.index + 1 }} of {{ pager.count }}.
                                        </div>
                                        <div class="badge badge-info float-right">
                                            Time: {{ ellapsedTime }} / {{ duration }}
                                        </div>
                                        <br />
                                        <h3 class="font-weight-normal mt-3">
                                            {{ pager.index + 1 }}.
                                            <span [innerHTML]="question.question"></span>
                                        </h3>
                                        <div style="min-height: 200px">
                                            <div class="row text-left options">
                                                <div class="col-lg-6 col-12" *ngFor="
                                                    let option of question.options
                                                ">
                                                    <div class="option">
                                                        <label class="font-weight-normal" [attr.for]="
                                                            option.id
                                                        ">
                                                            <input id="{{
                                                                option.id
                                                            }}" type="checkbox" [(ngModel)]="
                                                                option.selected
                                                            " (change)="
                                                                onSelect(
                                                                    question,
                                                                    option
                                                                )
                                                            " />
                                                            {{ option.option }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center">
                                        <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(0)">
                                            First
                                        </button>
                                        <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(pager.index - 1)">
                                            Prev
                                        </button>
                                        <button class="btn btn-primary" (click)="goTo(pager.index + 1)">
                                            Next
                                        </button>
                                        <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(pager.count - 1)">
                                            Last
                                        </button>
                                    </div>
                                </div>

                                <div class="row text-center" *ngIf="mode == 'review'" style="min-height: 400px">
                                    <div class="col-4 cursor-pointer" *ngFor="
                                        let question of questions;
                                        let index = index
                                    ">
                                        <div (click)="goTo(index)" class="p-3 mb-2 {{
                                            isAnswered(question) ==
                                            'Answered'
                                                ? 'bg-info'
                                                : 'bg-warning'
                                        }}">
                                            {{ index + 1 }}. {{ isAnswered(question) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="result" *ngIf="mode == 'result'" style="height: 500px; overflow-y: auto">
                                    <h2>the Result</h2>
                                    <div *ngFor="
                                        let question of questions;
                                        let index = index
                                    ">
                                        <div class="result-question">
                                            <h5>
                                                {{ index + 1 }}. {{ question.question }}
                                            </h5>
                                            <div class="row">
                                                <div class="col-6" *ngFor="
                                                    let Option of question.options
                                                ">
                                                    <input id="{{ Option.id }}" type="checkbox" disabled="disabled" [(ngModel)]="
                                                        Option.selected
                                                    " /> {{ Option.option }}
                                                </div>
                                            </div>
                                            <div class="p-1 m-2 alert {{
                                                isCorrect(question) ==
                                                'correct'
                                                    ? 'alert-success'
                                                    : 'alert-danger'
                                            }}">
                                                Your answer is {{ isCorrect(question) }}.
                                            </div>
                                        </div>
                                    </div>

                                    <h4 class="alert alert-info text-center">Your Result : {{((correct.length/questions.length)*100).toFixed(1)}} %</h4>
                                    <button class="btn btn-primary" (click)="repeatExamFun();" *ngIf="repeatExam == true">Repeate Exam</button>
                                    <p style="text-align:center;color:rgb(233, 48, 48)" *ngIf="repeatExam == false">you can ask your instructor to reopen this exam</p>


                                </div>
                                <hr />
                                <div *ngIf="mode != 'result'">
                                    <button class="btn btn-warning" (click)="mode = 'quiz'">
                                        Quiz
                                    </button>
                                    <button class="btn btn-info" (click)="mode = 'review'">
                                        Review
                                    </button>
                                    <button class="btn btn-primary" (click)="onSubmit()">
                                        Submit Quiz
                                    </button>
                                </div>
                            </div>

                            <div id="quiz" style="text-align:center;height:470px;padding-top:150px" *ngIf="notShowExam==true">
                                <h4 class="alert alert-info text-center">Your Result : {{((yourResult/questions.length)*100).toFixed(1)}} %</h4>

                                <button class="btn btn-primary" (click)="repeatExamFun();" *ngIf="repeatExam == true">Repeate Exam</button>

                                <p style="text-align:center;color:rgb(233, 48, 48)" *ngIf="repeatExam == false">you can ask your instructor to reopen this exam</p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 mt-md-5 mt-lg-0">
                <div class="main-title mt-2">
                    <h5 style="color: #2196f3">{{ CourseContent }}</h5>
                    <hr style="margin-top: -5px" />
                </div>
                <div class="card" style="margin-top: -20px; overflow-y: auto;max-height: 500px;">
                    <div *ngFor="let sec of section" id="accordion_lessons">
                        <div class="card-header" role="tab" id="headingOne">
                            <h5 class="mb-0" style="width: 100%">
                                <!-- <a data-toggle="collapse" [href]="'#collapse'+ sec[lang+'_name']" aria-expanded="true" aria-controls="collapseOne"-->
                                <a style="width: 100%" [href]="'#collapse' + sec[lang + '_name']" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <i class="indicator ti-minus"></i> {{ sec[lang + "_name"] }}
                                </a>
                            </h5>
                        </div>
                        <div [id]="'collapse' + sec[lang + '_name']">
                            <div class="card-body">
                                <div class="video-card video-card-list" *ngFor="let less of sec.lessons" [class]="
                                        getActive(less.lessons_id)
                                            ? 'activeless'
                                            : ''
                                    ">
                                    <div class="video-card-image" [id]="
                                            sec.en_name + '-' + less.lessons_id
                                        " *ngIf="less.type == 0">
                                        <a class="play-icon" (click)="play(less.video)"><i
                                                class="fa fa-play-circle"></i></a>
                                        <a><img style="height: 100%" class="img-fluid" src="{{
                                                    baseUrl + '/' + less.image
                                                }}" alt="" /></a>
                                        <div class="time">
                                            {{ less.duration }}
                                        </div>
                                    </div>

                                    <div class="video-card-image2" [id]="
                                            sec.en_name + '-' + less.lessons_id
                                        " *ngIf="less.type == 1">
                                        <a class="play-icon"></a>
                                        <a><img style="height: 100%" class="img-fluid" src="assets/img/course/pdf.png" alt="" /></a>
                                        <div class="time">
                                            {{ less.duration }}
                                        </div>
                                    </div>

                                    <div class="video-card-image2" [id]="
                                            sec.en_name + '-' + less.lessons_id
                                        " *ngIf="less.type == 2">
                                        <a class="play-icon"></a>
                                        <a class="play-icon" (click)="openExam()"><i class="fa fa-play-circle"></i></a>
                                        <a><img style="height: 100%" class="img-fluid" src="../../../../assets/img/quiz.jpg" alt="" /></a>
                                        <div class="time">
                                            what {{ less.duration }}
                                        </div>
                                    </div>

                                    <div class="video-card-body">
                                        <div class="video-title" style="padding-top: 10px">
                                            <a *ngIf="less.type == 0" style="cursor: pointer" (click)="play(less.video)">{{ less[lang + "_name"] }}</a>
                                            <a *ngIf="less.type == 2" style="cursor: pointer" (click)="openExam()">{{ less[lang + "_name"] }}</a>

                                            <br /> {{ less[lang + "_des"] }}
                                        </div>

                                        <div class="video-page">
                                            <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified" *ngIf="
                                                    getFinish(less.lessons_id)
                                                ">
                                                <i style="font-size: 20px" class="fa fa-check-circle text-success"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-3" *ngIf="!examExist && !getCertificateFlag">
            <div class="col-lg-8 col-md-12">
                <span>{{Ifyoufinishthiscourse}} </span>&ensp;&ensp;
                <button (click)="getCertificate()" type="submit" class="default-btn btnhover">
                    {{GetCertificate}}
                </button>

            </div>


        </div>

        <div class="row py-3" *ngIf="getCertificateFlag">


            <div class="row text-center alert alert-success">
                <p>{{yougotthecoursecertificate}}<a href="javascript:void(0)" (click)="openCertificateClickOn2()">{{SeeIt}}</a></p>
            </div>


        </div>
        <div id="badgeModal" class="modal" *ngIf="modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <span aria-hidden="true" style="cursor: pointer" (click)="closeModal()">×</span>
                    </div>
                    <div class="modal-body">

                        <app-black-certificate-big [certificate]="certificateImg" [valid]="getDate(valid)" [name]="nameincert" [title]="titleincert" [number]="numberinCert" type="big" [instructor]="instructorCert" [id]="'canvas'" [cID]="'canvas'"></app-black-certificate-big>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>