import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

    constructor(private _http : HttpClient) {

    }
    insertLog(userId:any,courseId:any,lessonId:any,curentTime:any,finish:any,score:any):Observable<any>{

      return this._http.post(environment.BaseUrl+'/log/insertLog',{
        userId:userId,
        courseId:courseId,
        lessonId:lessonId,
        curentTime:curentTime,
        finish:finish,
        score:score
      });
    }

    getLogs(userId:any,courseId:any):Observable<any>{
      return this._http.post(environment.BaseUrl+'/log/getLog',{
        userId:userId,
        courseId:courseId,
      });
    }

    checkLog(userId:any,courseId:any):Observable<any>{
      return this._http.post(environment.BaseUrl+'/log/checkLog',{
        userId:userId,
        courseId:courseId,
      });
    }
  }
