import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private _http : HttpClient) { }
  getAllCourses(catId?:any,search?:any,sortCol?:any,sortType?:any,start?:any,per_page?:any){
    if(!sortCol){
        sortCol=null;
        sortType=''
      }

    return this._http.post<any>(environment.BaseUrl+'/course/getAllCourse',{
        catId:catId,
        search:search,
        sortCol:sortCol,
        sortType:sortType,
        start:start,
        per_page:per_page
    });
  }


  getInstructorCourses(userId?:any,sortType?:any,sortCol?:any,start?:any,per_page?:any){
    if(!sortCol){
        sortCol=null;
        sortType=''
      }
    return this._http.post<any>(environment.BaseUrl+`/course/getInstructorCourses/${userId}`,{sortType:sortType,sortCol:sortCol,start:start,
        per_page:per_page});
    }

  getOneCourse(id?:any){
    return this._http.post<any>(environment.BaseUrl+`/course/getOne`,{id});
  }

  getCommentsForOneCourse(id?:any){
    return this._http.post<any>(environment.BaseUrl+`/course_rate/getComments`,{id});
  }

  rateCourse(userId:any,courseId:any,star:any){
    return this._http.post(environment.BaseUrl+`/course_rate/rateCourse`,{
      courseId:courseId,
      userId:userId,
      star:star
    });
  }

  reviewCourse(userId:any,courseId:any,review:any){
    return this._http.post(environment.BaseUrl+`/course_rate/reviewCourse/${courseId}`,{
      userId:userId,
      Comment:review
    });
  }



}
