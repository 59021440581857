import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { CourseService } from "src/app/services/course.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";
import { LessonService } from "src/app/services/lesson.service";
declare var $: any;
import Swal from "sweetalert2";
import { OrderService } from "src/app/services/order.service";

@Component({
    selector: "app-course-details",
    templateUrl: "./course-details.component.html",
    styleUrls: ["./course-details.component.scss"],
})
export class CourseDetailsComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    courseId: any;
    course: any = {};
    lang = "en";
    comments = [];
    courses = [];
    msg = false;
    totallessons = 0;
    lessons = [];
    section = [];
    msgText = "No products were found";
    Home: any;
    CourseDetails: any;
    reviews: any;
    Description: any;
    Reviews: any;
    AddToCart: any;
    RemovefromCart: any;
    CustomerReviews: any;
    Basedon: any;
    WriteaReview: any;
    SubmitReview: any;
    OurShop: any;
    RelatedProducts: any;
    on: any;
    Lessons: any;
    Enteryourname: any;
    Enteryouremail: any;
    Enteryourreviewatitle: any;
    Writeyourcommentshere: any;
    isAuthorized: any;
    user: any = null;
    email2: any = null;
    name = null;
    BuyNow: any = null;

    constructor(
        private auth: AuthenticationService,
        private _course: CourseService,
        private _cart: CartService,
        private _order: OrderService,
        private routerNavigation: Router,
        private router: ActivatedRoute,
        private _defult: DefaultService,
        public translate: TranslateService,
        public _lesson: LessonService
    ) {}

    ngOnInit(): void {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            this.email2 = this.user.email;
            this.name = this.user.firstName + " " + this.user.lastName;
            this.isAuthorized = this.auth.isAuthorized;
        }

        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        this.courseId = this.router.snapshot.paramMap.get("id");
        this.getCourse();
        this.getLessons();
        this.getCommentsForOneCourse();
    }
    getLabels() {
        this.translate
            .get([
                "Home",
                "CourseDetails",
                "reviews",
                "Description",
                "Reviews",
                "AddToCart",
                "RemovefromCart",
                "CustomerReviews",
                "Basedon",
                "WriteaReview",
                "SubmitReview",
                "OurShop",
                "RelatedProducts",
                "On",
                "Lessons",
                "Enteryourname",
                "Enteryouremail",
                "Enteryourreviewatitle",
                "Writeyourcommentshere",
                "BuyNow",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.CourseDetails = res.CourseDetails;
                this.reviews = res.reviews;
                this.Description = res.Description;
                this.Reviews = res.Reviews;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
                this.CustomerReviews = res.CustomerReviews;
                this.Basedon = res.Basedon;
                this.WriteaReview = res.WriteaReview;
                this.SubmitReview = res.SubmitReview;
                this.OurShop = res.OurShop;
                this.RelatedProducts = res.RelatedProducts;
                this.on = res.On;
                this.Lessons = res.Lessons;
                this.Enteryourname = res.Enteryourname;
                this.Enteryouremail = res.Enteryouremail;
                this.Enteryourreviewatitle = res.Enteryourreviewatitle;
                this.Writeyourcommentshere = res.Writeyourcommentshere;
                this.BuyNow = res.BuyNow;
            });
    }
    getRelatedCourses(category_id) {
        this._course
            .getAllCourses(
                category_id,
                "",
                null,
                null,

                9999999999,
                0
            )
            .subscribe(
                (res) => {
                    //  console.log(res)
                    // to check if more courses exists
                    if (res.apiStatus) {
                        this.courses = res.data;
                        this.courses = this.courses.filter(
                            (e) => e.id != this.courseId
                        );

                        if (this.courses.length == 0) {
                            this.msg = true;
                        }
                    }
                },
                (err) => {
                    this.msg = true;

                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }

    getCourse() {
        this._course.getOneCourse(this.courseId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.course = res.data[0];

                    /*  var lessons = res.data[0].lessons;
        console.log(lessons)
        lessons = $.parseJSON('[' + lessons + ']');


        if(lessons.length>0 && lessons[0].lessons_id !=null ){
        this.totallessons = lessons.length;


        this.getLessons(lessons);
        }*/

                    this.getRelatedCourses(this.course.category_id);
                }
                // console.log(this.course)
            },
            (err) => {
                // console.log(err)
            }
        );
    }

    buyNow(course) {
        localStorage.setItem("previous_link", "/checkout/2");

        this._cart.clearCart2();
        if (!this._cart.itemInCart2(course)) {
            this._cart.addToCart2(course); //add items in cart
        }

        this.routerNavigation.navigateByUrl("/checkout/2");
    }

    getLessons() {
        this._lesson.getAllLessons(this.courseId).subscribe(
            (res) => {
                this.lessons = res.data;
                //console.log(res.data, this.courseId);
                this.lessons.forEach((e) => {
                    let num = -1;
                    for (var i = 0; i < this.section.length; i++) {
                        if (this.section[i].en_name == e.en_section) {
                            num = i;
                        }
                    }

                    if (num == -1) {
                        this.section.push({
                            ar_name: e.ar_section,
                            en_name: e.en_section,
                            lessons: [e],
                        });
                    } else {
                        this.section[num].lessons.push(e);
                    }
                });
            },
            (err) => {}
        );
    }

    getCommentsForOneCourse() {
        this._course.getCommentsForOneCourse(this.courseId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.comments = res.data;
                }
                // console.log(this.course)
            },
            (err) => {
                //console.log(err);
            }
        );
    }

    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    //check if item exists
    isCourseExist(course) {
        if (this._cart.itemInCart(course)) {
            return true;
        } else {
            return false;
        }
    }

    //add to cart
    addToCart(course) {
        if (!this._cart.itemInCart(course)) {
            this._cart.addToCart(course); //add items in cart
        }
    }

    // remove course from cart
    removeFromCart(course) {
        if (this._cart.itemInCart(course)) {
            this._cart.removeItem(course); //add items in cart
        }
    }
    goToCourse(id) {
        this.routerNavigation.navigate(["/courses-details/" + id]).then(() => {
            location.reload();
        });
    }

    submitReview(review) {
        this._course
            .reviewCourse(this.user.id, this.courseId, review.value)
            .subscribe(
                (res) => {
                    review.value = "";
                    this.getCommentsForOneCourse();
                },
                (err) => {
                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
    enroll(e) {
        if (this.user) {
            let app = this;
            Swal.fire({
                title: "Are you sure?",
                text: "Do you want to get  this course?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, i want!",
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    app.sureEnroll(e)
                        .then((data) =>
                            Swal.fire(
                                "Done!",
                                "This course has been added to you",
                                "success"
                            )
                        )
                        .catch((data) => Swal.fire("Error", data, "error"));
                }
            });
        } else {
            this.routerNavigation.navigateByUrl("/login");
        }
    }

    sureEnroll(e): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            let total = 0;
            this._order
                .createOne(
                    0,
                    null,
                    1,
                    this.user.id,
                    total,
                    this.email2,
                    this.name
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            this._cart
                                .createOne(
                                    this.user.id,
                                    e.id,
                                    0,
                                    0,
                                    e.cat_id,
                                    res.data.id,
                                    this.email2,
                                    this.name
                                )

                                .subscribe(
                                    (res) => {
                                        if (res.apiStatus) {
                                            resolve(true);
                                        } else {
                                            reject(true);
                                        }
                                    },
                                    (err) => {
                                        //console.log(err);
                                        reject(true);
                                    }
                                );
                        } else {
                            reject(true);
                        }
                    },
                    (err2) => {
                        //console.log(err2);
                        reject(true);
                    }
                );
        });

        return promise;
    }
}
