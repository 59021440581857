<app-navbar-two></app-navbar-two>
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ForgetYourPassword}}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ForgetYourPassword}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>{{ForgetYourPassword}}</h3>
                <p>{{PleaseWriteYourEmail}}</p>
            </div>

            <div class="row text-center alert alert-success" *ngIf="msg != null">
                {{PleaseCheckYourEmail}}
            </div>




            <form id="formLogin">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" #email id='email' />
                            <a id="error" style="color: red"></a>
                        </div>
                    </div>



                    <!-- <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep me Login</label>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-12 text-end">
                        <p class="forgot-password">
                            <a routerLink="/">Forgot Password?</a>
                        </p>
                    </div> -->


                    <div class="col-lg-12 ">
                        <div class="send-btn ">
                            <a (click)="confirm(email) " class="default-btn-one ">{{Confirm}}</a>
                        </div>

                    </div>


                </div>
            </form>
        </div>
    </div>
</div>