import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CertificationsService {
    constructor(private _http: HttpClient) {}

    getUserCertificates(student_id?: any) {
        return this._http.post<any>(
            environment.BaseUrl +
                "/certificate-student/getAllUserCertificates/",
            { student_id }
        );
    }

    checkCertificate(student_id?: any, certificate_id?: any, product_id?: any) {
        return this._http.post<any>(
            environment.BaseUrl + "/certificate-student/checkUserCertificates/",
            { student_id, certificate_id, product_id }
        );
    }

    getOneCertificate(id, student_id, courseId) {
        return this._http.post<any>(
            environment.BaseUrl + "/certificate/getOneCertificate/",
            { id, student_id, courseId }
        );
    }
}
