<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Maids</h2>

                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>Maids</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section inner-team ptb-100" >
    <div class="container">
        <div class="row text-center alert alert-danger" *ngIf="message">
            {{ message }}
        </div>   
       
        <div class="row align-items-center" *ngIf="!message">
            <div  class="col-lg-3 col-md-6 col-sm-6"
              
            >
                <div class="single-team-member">
                    <div class="member-image">
                        <img
                        [src]=" instructor?.photo"
                            alt="image"
                        />
                    </div>

                  
                </div>
            </div>
            <div  class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-content">
                        <h3>
                            <a><span id="titles">Name: </span>{{
                                instructor?.name
                            }}</a>
                        </h3>
                        <p>
                            <span id="titles">Price: </span>
                            AED
                            {{ instructor?.salary }}</p>
                            <p>
                                <span id="titles">Nation: </span>
                                {{instructor?.nationality}}
                            </p>
                            <p>
                                <span id="titles">Experience: </span>
                                {{instructor?.experience}}
                            </p>
                            <h5>
                                <span id="titles">Resume: </span>
                                <a href="{{instructor?.resume}}">Download</a>
                            </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
