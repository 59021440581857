import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";
import { BookService } from "src/app/services/book.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { StudentService } from "src/app/services/student.service";
import { environment } from "src/environments/environment.prod";
import Swal from "sweetalert2";
declare var $: any;
@Component({
    selector: "app-mybooks",
    templateUrl: "./mybooks.component.html",
    styleUrls: ["./mybooks.component.scss"],
})
export class MybooksComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    sort = null;
    msg = false;
    msgText = "No products were found";
    books = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    BooksList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;
    user: any = {};

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private _defult: DefaultService,
        public translate: TranslateService,
        private _http : HttpClient
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
       

        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
        } else {
            this.router.navigateByUrl("/");
        }

     
   this.getAllOnApp()
    }
    cancle(id:any){
        this._http.put<any>(environment.BaseUrl + `/cancel-reserve/${id}`,{}).subscribe(
            (res) => {
            console.log(res); 
            Swal.fire({
                title: "",
                text: `Reservation Cancelled Successfully`,
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
                showLoaderOnConfirm: true,
            }).then((result) => {
                this.getAllOnApp()
            });
           
          }
            ,(err:any)=>{
                this.msg = true;
                this.msgText = "No Data Were Found!";
            });
            
    }
    Checkout(id:any){

    }
    allReservations : any = []
    currentTap : string = "pending"
    changeTap(tap:any){
        this.currentTap = tap;
        this.getAllOnApp()
    }
    getAllOnApp() {
        this._http.get<any>(environment.BaseUrl + `/reserve?q=${this.currentTap}`).subscribe(
          (res) => {
          console.log(res);
          this.allReservations = res.data; 
   
        }
          ,(err:any)=>{
              this.msg = true;
              this.msgText = "No Data Were Found!";
          }
          
          );
      }



  

   
}
