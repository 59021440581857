<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Offers</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>Offers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>
                            {{ Showing }} {{ (offset - 1) * limit + 1 }}-{{ (offset - 1) * limit + 1 + limit }} {{ of }} {{ total_pages }} {{ results }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select class="selectbook">
                            <option
                                *ngFor="let item of filterSort"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-center alert alert-danger" *ngIf="msg">
            {{ msgText }}
        </div>
        <div class="row" *ngIf="offers.length > 0">
            <div class="col-lg-4 col-md-8 col-sm-12 mx-md-auto mx-lg-0" *ngFor="let book of offers">
                <div class="single-product-box">
                    <div class="product-image">
                        <a  class="d-block"><img src="{{ book?.image }}" alt="image" /></a>

                        <a  (click)="addToCart(book.id , book.price)" class="add-to-cart-btn">Book Offer Now
                            <i class="flaticon-shopping-cart"></i
                        ></a>
                     
                    </div>

                    <div class="product-content">
                        <h3>
                            <a >{{
                                book?.title
                            }}</a>
                        </h3>

                        <div class="price">
                            <p>
                                {{ book?.desc}}
                            </p>
                            <span class="new" >AED
                                {{ book.price}}</span
                            >
                            <p>
                                Active to {{book.to | date}}
                            </p>
                        </div>

                    
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a
                        class="prev page-numbers"
                        class="{{ offset <= 0 ? 'disable' : 'enable' }}"
                        (click)="offset = offset - 1; changeOffset()"
                        ><i class="fas fa-angle-double-left"></i
                    ></a>
                    <a
                        aria-current="page"
                        class="page-numbers {{ i == offset ? 'current' : '' }}"
                        *ngFor="let num of number; let i = index"
                        (click)="offset = i; changeOffset()"
                        >{{ i + 1 }}</a
                    >
                    <a
                        class="next page-numbers"
                        class="{{
                            offset >= total_pages - 1 ? 'disable' : 'enable'
                        }}"
                        (click)="offset = offset + 1; changeOffset()"
                        ><i class="fas fa-angle-double-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>


   