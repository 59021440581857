import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BookService } from "src/app/services/book.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";

@Component({
    selector: "app-book-details",
    templateUrl: "./book-details.component.html",
    styleUrls: ["./book-details.component.scss"],
})
export class BookDetailsComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    bookId: any;
    book: any = {};
    lang = "en";
    comments = [];
    books = [];
    msg = false;
    msgText = "No products were found";
    Home: any;
    BookDetails: any;
    reviews: any;
    Description: any;
    Reviews: any;
    AddToCart: any;
    RemovefromCart: any;
    CustomerReviews: any;
    Basedon: any;
    WriteaReview: any;
    SubmitReview: any;
    OurShop: any;
    RelatedProducts: any;
    on: any;
    isAuthorized: any;
    BuyNow: any = null;
    user: any = {};

    constructor(
        private auth: AuthenticationService,
        private _book: BookService,
        private _cart: CartService,
        private routerNavigation: Router,
        private router: ActivatedRoute,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            this.isAuthorized = this.auth.isAuthorized;
        }

        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        this.bookId = this.router.snapshot.paramMap.get("id");
        this.getBook();
        this.getCommentsForOneBook();
    }
    getLabels() {
        this.translate
            .get([
                "Home",
                "BookDetails",
                "reviews",
                "Description",
                "Reviews",
                "AddToCart",
                "BuyNow",
                "RemovefromCart",
                "CustomerReviews",
                "Basedon",
                "WriteaReview",
                "SubmitReview",
                "OurShop",
                "RelatedProducts",
                "On",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.BookDetails = res.BookDetails;
                this.reviews = res.reviews;
                this.Description = res.Description;
                this.Reviews = res.Reviews;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
                this.CustomerReviews = res.CustomerReviews;
                this.Basedon = res.Basedon;
                this.WriteaReview = res.WriteaReview;
                this.SubmitReview = res.SubmitReview;
                this.OurShop = res.OurShop;
                this.RelatedProducts = res.RelatedProducts;
                this.on = res.On;
                this.BuyNow = res.BuyNow;
            });
    }
    getRelatedBooks(category_id) {
        //  console.log(category_id)

        this._book
            .getAllBooks(
                category_id,
                "",
                null,
                null,

                9999999999,
                0
            )
            .subscribe(
                (res) => {
                    //  console.log(res)
                    // to check if more books exists
                    if (res.apiStatus) {
                        this.books = res.data;
                        this.books = this.books.filter(
                            (e) => e.id != this.bookId
                        );

                        if (this.books.length == 0) {
                            this.msg = true;
                        }
                    }
                },
                (err) => {
                    this.msg = true;

                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }

    getBook() {
        this._book.getOneBook(this.bookId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.book = res.data[0];
                    this.getRelatedBooks(this.book.category_id);
                }
                // console.log(this.book)
            },
            (err) => {
                // console.log(err)
            }
        );
    }
    getCommentsForOneBook() {
        this._book.getCommentsForOneBook(this.bookId).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.comments = res.data;
                }
                // console.log(this.book)
            },
            (err) => {
                //console.log(err);
            }
        );
    }
    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    //check if item exists
    isBookExist(book) {
        if (this._cart.itemInCart(book)) {
            return true;
        } else {
            return false;
        }
    }

    //add to cart
    addToCart(book) {
        if (!this._cart.itemInCart(book)) {
            this._cart.addToCart(book); //add items in cart
        }
    }

    // remove book from cart
    removeFromCart(book) {
        if (this._cart.itemInCart(book)) {
            this._cart.removeItem(book); //add items in cart
        }
    }

    buyNow(book) {
        localStorage.setItem("previous_link", "/checkout/2");
        this._cart.clearCart2();
        if (!this._cart.itemInCart2(book)) {
            this._cart.addToCart2(book); //add items in cart
        }

        this.routerNavigation.navigateByUrl("/checkout/2");
    }

    goToBook(id) {
        this.routerNavigation.navigate(["/book-details/" + id]).then(() => {
            location.reload();
        });
    }

    submitReview(review) {
        this._book
            .reviewBook(this.user.id, this.bookId, review.value)
            .subscribe(
                (res) => {
                    review.value = "";
                    this.getCommentsForOneBook();
                },
                (err) => {
                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
}
