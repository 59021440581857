import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {
  transform(data: any[], filterValue: string): any[] {
    if (!data || !filterValue) {
      return data;
    }

    return data.filter(item => item.name.includes(filterValue.toUpperCase()));
  }
}