<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{CourseDetails}}</h2>

                    <ul>
                        <li><a routerLink="/">{{Home}}</a></li>
                        <li>{{CourseDetails}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="product-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <img src="{{baseUrl+'/'+course.image}}" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3>{{course[lang+'_name']}}</h3>

                    <div class="price">
                        <span class="new-price">{{course.price_unit}} {{course.discount}}</span>
                        <span class="old-price">{{course.price_unit}} {{course.price}}</span>
                    </div>

                    <div class="product-review">

                        <div class="rating">
                            <i [class]="0<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                            <i [class]="1<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                            <i [class]="2<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                            <i [class]="3<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                            <i [class]="4<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                        </div>

                        <a routerLink="/" class="rating-count">{{course.sum_rate}}{{reviews}}</a>
                    </div>

                    <p *ngIf="course[lang+'_desc']">{{(course[lang+'_des']).substring(0, 100)}}</p>

                    <div class="product-add-to-cart">
                        <!-- <div class="input-counter">
                            <span class="minus-btn">
                                <i class="fas fa-minus"></i>
                            </span>
                            <input type="text" min="1" value="1">
                            <span class="plus-btn">
                                <i class="fas fa-plus"></i>
                            </span>
                        </div>-->

                    </div>


                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li>
                            <a style="cursor:pointer">
                                <div class="dot"></div>
                                {{Description}}
                            </a>
                        </li>
                        <li>
                            <a style="cursor:pointer">
                                <div class="dot"></div>
                                {{Lessons}}
                            </a>
                        </li>
                        <li>
                            <a style="cursor:pointer">
                                <div class="dot"></div>
                                {{Reviews}}
                            </a>
                        </li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <p>{{course[lang+'_des']}}
                                </p>
                            </div>
                        </div>


                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="intro_title">
                                    <ul>
                                        <li>{{totallessons}} {{Lessons}}</li>
                                        <li>{{course?.duration}}</li>
                                    </ul>
                                </div>
                                <br>

                                <div id="accordion_lessons" role="tablist" class="add_bottom_45">
                                    <div class="card" *ngFor="let sec of section">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <h5 class="mb-0">
                                                <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <i class="indicator ti-minus"></i> {{sec[lang+'_name']}}
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion_lessons">
                                            <div class="card-body">
                                                <div class="list_lessons">
                                                    <ul>
                                                        <li *ngFor="let les of sec.lessons">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="width:80%">
                                                                            <a href="{{les.video}}" *ngIf="les.type==0"><i class="fas fa-play"></i>&ensp;{{les[lang+'_name']}}</a>
                                                                            <a href="{{baseUrl+'/'+les.video}}" *ngIf="les.type==1"><i class="fas fa-file"></i>&ensp;{{les[lang+'_name']}}</a>
                                                                        </td>

                                                                        <td style="width:20%"> <span>{{les.duration}}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- /card -->

                                </div>
                            </div>
                        </div>



                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>{{CustomerReviews}}</h3>

                                    <div class="review-title">
                                        <div class="rating">
                                            <i [class]="0<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                                            <i [class]="1<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                                            <i [class]="2<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                                            <i [class]="3<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                                            <i [class]="4<course.rate && course.rate<=5? 'fas fa-star':'far fa-star'"></i>
                                        </div>
                                        <p>{{Basedon}} {{course.sum_rate}} {{reviews}}</p>

                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item" *ngFor="let comment of comments">
                                            <div class="rating">
                                                <i [class]="comment.rate_1==1 || comment.rate_2==1||comment.rate_3==1||comment.rate_4==1 ||comment.rate_5==1 ? 'fas fa-star':'far fa-star'"></i>
                                                <i [class]="comment.rate_2==1||comment.rate_3==1||comment.rate_4==1 ||comment.rate_5==1 ? 'fas fa-star':'far fa-star'"></i>
                                                <i [class]="comment.rate_3==1||comment.rate_4==1 ||comment.rate_5==1? 'fas fa-star':'far fa-star'"></i>
                                                <i [class]="comment.rate_4==1 ||comment.rate_5==1? 'fas fa-star':'far fa-star'"></i>
                                                <i [class]="comment.rate_5==1? 'fas fa-star':'far fa-star'"></i>
                                            </div>
                                            <h3>{{comment.firstName +" "+comment.lastName}}</h3>
                                            <span>on <strong>{{getDate(comment.createdAt)}}</strong></span>
                                            <p>{{comment.Comment}}</p>
                                            <!--<a href="#" class="review-report-link">Report as Inappropriate</a>-->
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section>