<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ BookDetails }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ BookDetails }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Digital Marketing</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>

                        <div class="features-text">
                            <h4><i class="flaticon-check-mark"></i> Core Development</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="flaticon-check-mark"></i> Define Your Choices</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/services-details1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/services-details2.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Social Media Marketing</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>

                        <div class="services-details-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)"><i class="fas fa-plus"></i> Which material types can you work with?</a>
                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-plus"></i> Is Smart Lock required for instant apps?</a>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-plus"></i> Why Choose Our Services In Your Business?</a>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
<section class="product-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <img src="{{ baseUrl + '/' + book.image }}" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3>{{ book[lang + "_name"] }}</h3>

                    <div class="price">
                        <span class="new" *ngIf="book.discount > 0">{{ book.price_unit }} {{ book.discount }}</span>
                        <span class="old" style="text-decoration: line-through; color: grey;margin-left:10px;" *ngIf="book.discount > 0">{{ book.price_unit }} {{ book.price }}</span>
                        <span class="new" *ngIf="book.discount == 0">{{ book.price_unit }} {{ book.price }}</span>
                    </div>

                    <div class="product-review">
                        <div class="rating">
                            <i [class]="
                                    0 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    1 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    2 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    3 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    4 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                        </div>

                        <a class="rating-count">{{ book.sum_rate }}{{ reviews }}</a>
                    </div>

                    <p *ngIf="book[lang + '_desc']">
                        {{ book[lang + "_desc"].substring(0, 100) }}
                    </p>

                    <div class="product-add-to-cart">
                        <!-- <div class="input-counter">
                            <span class="minus-btn">
                                <i class="fas fa-minus"></i>
                            </span>
                        <input type="text" min="1" value="1">
                        <span class="plus-btn">
                                <i class="fas fa-plus"></i>
                            </span>
                    </div>-->
                        <button *ngIf="!isBookExist(book)" (click)="addToCart(book)" type="submit" class="default-btn">
                            <i class="fas fa-cart-plus"></i>{{ AddToCart }}
                        </button>
                        <button *ngIf="isBookExist(book)" (click)="removeFromCart(book)" type="submit" class="default-btn btnhover" style="background-color: #ffffff; color: #fd5a0e">
                            <i class="fas fa-cart-plus"></i>
                            {{ RemovefromCart }}
                        </button>

                        <button style="margin-left:20px" (click)="buyNow(book)" type="submit" class="default-btn">
                            {{ BuyNow }}
                        </button>



                    </div>

                    <!--<div class="buy-checkbox-btn">
                        <div class="item">
                            <input class="inp-cbx" id="cbx" type="checkbox">
                            <label class="cbx" for="cbx">
                                <span>
                                    <svg width="12px" height="10px" viewbox="0 0 12 10">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span>I agree with the terms and conditions</span>
                            </label>
                        </div>

                        <div class="item">
                            <a routerLink="/" class="btn btn-light">Buy it now!</a>
                        </div>
                    </div>

                    <div class="custom-payment-options">
                        <span>Guaranteed safe checkout:</span>

                        <div class="payment-methods">
                            <a href="#"><img src="assets/img/payment/1.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/2.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/3.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/4.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/5.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/6.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/7.svg" alt="image"></a>
                        </div>
                    </div>-->
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li>
                            <a style="cursor: pointer">
                                <div class="dot"></div>
                                {{ Description }}
                            </a>
                        </li>
                        <li>
                            <a style="cursor: pointer">
                                <div class="dot"></div>
                                {{ Reviews }}
                            </a>
                        </li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <p>{{ book[lang + "_desc"] }}</p>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>{{ CustomerReviews }}</h3>

                                    <div class="review-title">
                                        <div class="rating">
                                            <i [class]="
                                                    0 < book.rate &&
                                                    book.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    1 < book.rate &&
                                                    book.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    2 < book.rate &&
                                                    book.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    3 < book.rate &&
                                                    book.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    4 < book.rate &&
                                                    book.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                        </div>
                                        <p>
                                            {{ Basedon }} {{ book.sum_rate }} {{ reviews }}
                                        </p>
                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item" *ngFor="let comment of comments">
                                            <div class="rating">
                                                <i [class]="
                                                        comment.rate_1 == 1 ||
                                                        comment.rate_2 == 1 ||
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_2 == 1 ||
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                            </div>
                                            <h3>
                                                {{ comment.firstName + " " + comment.lastName }}
                                            </h3>
                                            <span>{{ on }}
                                                <strong>{{
                                                    getDate(comment.createdAt)
                                                }}</strong></span>
                                            <p>{{ comment.Comment }}</p>
                                            <!--<a href="#" class="review-report-link">Report as Inappropriate</a>-->
                                        </div>
                                    </div>

                                    <div class="review-form" *ngIf="
                                            isAuthorized &&
                                            user.type == 'student'
                                        ">
                                        <h3>{{ WriteaReview }}</h3>

                                        <form>
                                            <div class="row">
                                                <!--   <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" id="name" name="name" placeholder="Enter your name" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="email" id="email" name="email" placeholder="Enter your email" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <input type="text" id="review-title" name="review-title" placeholder="Enter your review a title" class="form-control">
                                                    </div>
                                                </div>-->

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea name="review-body" id="review-body" cols="30" rows="7" placeholder="Write your comments here" #review class="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="default-btn" (click)="
                                                            submitReview(review)
                                                        ">
                                                        {{ SubmitReview }}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="related-products">
        <div class="container">
            <div class="products-title">
                <span class="sub-title">{{ OurShop }}</span>
                <h2>{{ RelatedProducts }}</h2>
            </div>

            <div class="row text-center alert alert-danger" *ngIf="msg || books.length == 0">
                {{ msgText }}
            </div>
            <div class="row" *ngIf="books.length > 0">
                <div class="col-lg-4 col-md-8 col-sm-12 mx-md-auto mx-lg-0" *ngFor="let book2 of books">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a (click)="goToBook(book2.id)" style="cursor: pointer" class="d-block"><img src="{{ baseUrl + '/' + book2.image }}" alt="image" /></a>

                            <a *ngIf="!isBookExist(book2)" (click)="addToCart(book2)" class="add-to-cart-btn">{{ AddToCart }}
                                <i class="flaticon-shopping-cart"></i></a>
                            <a *ngIf="isBookExist(book2)" (click)="removeFromCart(book2)" class="add-to-cart-btn">{{ RemovefromCart
                                }}<i class="flaticon-shopping-cart"></i></a>
                        </div>

                        <div class="product-content">
                            <h3>
                                <a (click)="goToBook(book2.id)" style="cursor: pointer">{{ book2[lang + "_name"] }}</a>
                            </h3>

                            <div class="price">
                                <span class="new" *ngIf="book2.discount > 0">{{ book2.price_unit }}
                                    {{book2.discount}}</span>
                                <span class="old" style="text-decoration: line-through; color: grey;margin-left:10px;" *ngIf="book2.discount > 0">{{ book2.price_unit }} {{ book2.price }}</span>
                                <span class="new" *ngIf="book2.discount == 0">{{ book2.price_unit }}
                                    {{ book2.price }}</span>
                            </div>

                            <div class="rating">
                                <i [class]="
                                        0 < book2.rate && book2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        1 < book2.rate && book2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        2 < book2.rate && book2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        3 < book2.rate && book2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        4 < book2.rate && book2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>