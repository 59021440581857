<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Maids</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>Maids</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section inner-team ptb-100">
    <div class="container">
        
        <div class="row text-center alert alert-danger" *ngIf="msg">
            {{ msgText }}
        </div>
        <div class="row text-center form-group filter">
            <input type="text" class="form-control" [(ngModel)]="filterValue" placeholder="Filter by name">
        </div>
        <div class="row align-items-center" *ngIf="instructors.length > 0">
          
            <div
                class="col-lg-3 col-md-6 col-sm-6"
                *ngFor="let instructor of instructors | filterByName: filterValue"
            >
                <div class="single-team-member" style="cursor: pointer;">
                    <div class="member-image" [routerLink]="['/single-maid/' + instructor?.id]">
                        <img
                        [src]=" instructor?.photo"
                            alt="image"
                        />
                    </div>

                    <div class="member-content">
                        <h3>
                            <a [routerLink]="['/single-maid/' + instructor.id]">Maid Name: {{
                                instructor.name
                            }}</a>
                        </h3>
                        Maid Salary: 
                        <span>AED
                            {{ instructor.salary }}</span>
                            <p>
                               Maid Nationality: {{instructor?.nationality}}
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
