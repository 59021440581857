<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Cart }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Cart }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">{{ Product }}</th>
                            <th scope="col">{{ Name }}</th>
                            <th scope="col">{{ Price }}</th>
                            <th scope="col">{{ AfterDiscount }}</th>
                            <th scope="col">{{ Action }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let product of cart">
                            <td class="product-thumbnail">
                                <a routerLink="/"><img src="{{
                                            baseUrl + '/' + product.image
                                        }}" alt="item" /></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/">{{
                                    product[lang + "_name"]
                                }}</a>
                            </td>



                            <td class="product-price"><span class="unit-amount">{{product.price_unit}}
                                    {{product.price}}</span></td>

                            <td class="product-price"><span class="unit-amount">{{product.price_unit}}
                                    {{product.discount}}</span></td>



                            <td class="product-name">
                                <a style="cursor: pointer" (click)="remove(product)" class="remove"><i
                                        class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Coupon code"
                                name="coupon-code"
                                id="coupon-code"
                            />
                            <button type="submit">{{ ApplyCoupon }}</button>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="cart-totals">
                <h3>{{ CartTotals }}</h3>

                <ul>
                    <li>
                        {{ Total }}
                        <span><b>{{ total }} $</b></span>
                    </li>
                </ul>

                <a routerLink="/checkout/1" class="default-btn">{{
                    ProceedtoCheckout
                }}</a>
            </div>
        </form>
    </div>
</section>