<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Profile }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Profile }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/hero_in-->

<section class="shop-area ptb-20">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <aside id="sidebar" class="mt-5">
                        <div id="filters_col">
                            <h6 class="classh6">
                                <a
                                    data-toggle="collapse"
                                    href="#collapseFilters"
                                    aria-expanded="false"
                                    aria-controls="collapseFilters"
                                    id="filters_col_bt"
                                >
                                    {{ Menu }}
                                </a>
                            </h6>
                            <div class="collapse show" id="collapseFilters">
                                <div class="filter_type">
                                    <h6></h6>
                                    <ul>
                                        <li *ngFor="let btntab of btnTabs">
                                            <label style="width: 100%">
                                                <button
                                                    class="btn btn-defult btnSideBar {{
                                                        btntab.active
                                                            ? 'BSactive'
                                                            : ''
                                                    }}"
                                                    (click)="
                                                        changeTab(btntab.id)
                                                    "
                                                >
                                                    {{ btntab[lang + "_name"] }}
                                                </button>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--/collapse -->
                        </div>
                        <!--/filters col-->
                    </aside>
                </div>

                <div class="col-lg-8 col-12">
                    <div id="profile" *ngIf="idTab == 'profile'">
                        <!-- Breadcrumbs-->
                        <div
                            class="box_general padding_bottom"
                            style="position: relative"
                        >
                            <div
                                class="row text-center alert alert-danger"
                                *ngIf="msg && success == false"
                            >
                                {{ msgtxt }}
                            </div>
                            <div
                                class="row text-center alert alert-success"
                                *ngIf="msg && success == true"
                            >
                                {{ msgtxt }}
                            </div>

                            <div class="header_box version_2">
                                <h6 class="classh6">
                                    <i class="fa fa-file"> </i> &ensp;{{
                                        BasicInformation
                                    }}
                                </h6>
                            </div>

                            <div class="row">
                                <div
                                    class="col-md-12 mb-3"
                                    style="text-align: center"
                                >
                                    <img
                                        src="{{ imageBase64 }}"
                                        class="imgProfile"
                                        #imageView
                                        (click)="clickImage(fileElement)"
                                    />
                                    <input
                                        type="file"
                                        #fileElement
                                        style="display: none"
                                        (change)="setImage($event, imageView)"
                                    />
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label">{{ Name }}</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value="{{ user.name }}"
                                            #firstName
                                            name="firstName"
                                            kerror="The field cannot be empty"
                                            required
                                        />
                                    </div>
                                </div>
                             
                            </div>

                            <!-- /row-->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label">{{
                                            Telephone
                                        }}</label>
                                        <input
                                            style="outline: 0 !important"
                                            type="text"
                                            class="form-control"
                                            value="{{ user.phone }}"
                                            #phone
                                            kerror="The field cannot be empty"
                                            name="phoneNumber"
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label">{{ Email }}</label>
                                        <input
                                            style="outline: none"
                                            type="email"
                                            class="form-control"
                                            value="{{ user.email }}"
                                            #email
                                            disabled
                                        />
                                    </div>
                                </div>

                                <br />
                                <br />
                            </div>
                            <br />

                            <div class="row" style="padding: 20px">
                                <button
                                    class="default-btn"
                                    style="float: right"
                                    (click)="
                                        setProfile(
                                            firstName,
                                        
                                            email,
                                            phone
                                        )
                                    "
                                >
                                    {{ Save }}
                                </button>
                            </div>

                            <!-- /row-->
                        </div>

                        <!-- /box_general-->
                    </div>
                    <div id="courses" *ngIf="idTab == 'courses'">
                        <app-my-courses></app-my-courses>
                    </div>
                    <div id="changePassword" *ngIf="idTab == 'changePassword'">
                        <app-change-password></app-change-password>
                    </div>
                    <div id="books" *ngIf="idTab == 'reservations'">
                        <app-mybooks></app-mybooks>
                    </div>

                    <div id="certificates" *ngIf="idTab == 'certificates'">
                        <app-my-certificates></app-my-certificates>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Modal -->
<div
    class="modal fade"
    id="modelImageCrop"
    style="z-index: 9999999"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" style="margin-top: 14%">
        <div class="modal-content" style="border-radius: 0px">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <a
                    class="btn-close pe-7s-close"
                    style="font-size: 30px"
                    (click)="closeModel('modelImageCrop')"
                ></a>
            </div>
            <div class="modal-body">
                <image-cropper
                    imageBase64="{{ imageBase64 }}"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 3"
                    [resizeToWidth]="256"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                ></image-cropper>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    style="border-radius: 0px"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    style="border-radius: 0px"
                    (click)="saveImage()"
                >
                    Save changes
                </button>
            </div>
        </div>
    </div>
</div>
