<section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>
                            {{ Showing }} {{ (offset - 1) * limit + 1 }}-{{ (offset - 1) * limit + 1 + limit }} {{ of }} {{ total_pages }} {{ results }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select class="selectMyCourses">
                            <option
                                *ngFor="let item of filterSort"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center alert alert-danger" *ngIf="msg || courses.length == 0">
            {{ msgText }}
        </div>
        <div class="row" *ngIf="courses.length > 0">
            <div class="col-lg-6 col-md-8 col-sm-12 mx-md-auto mx-lg-0" *ngFor="let course of courses">
                <div class="single-product-box">
                    <div class="product-image">
                        <a *ngIf="user.type == 'student'" [routerLink]="['/show-my-courses/' + course.id]" class="d-block"><img src="{{ baseUrl + '/' + course.image }}" alt="image" /></a>
                        <a *ngIf="user.type == 'instructor'" [routerLink]="[
                                '/courses-details-instructor/' + course.id
                            ]"><img src="{{ baseUrl + '/' + course.image }}" alt="image" /></a>
                    </div>

                    <div class="product-content">
                        <h3>
                            <a *ngIf="user.type == 'student'" [routerLink]="['/show-my-courses/' + course.id]">{{ course[lang + "_name"] }}</a
                            >
                            <a
                                *ngIf="user.type == 'instructor'"
                                [routerLink]="[
                                    '/courses-details-instructor/' + course.id
                                ]"
                                >{{ course[lang + "_name"] }}</a
                            >
                        </h3>

                        <div class="price">
                            <span class="new" *ngIf="course.discount > 0"
                                >{{ course.price_unit }}
                                {{ course.discount }}</span
                            >
                            <span
                                class="old"
                                style="
                                    text-decoration: line-through;
                                    color: grey;
                                    margin-left: 10px;
                                "
                                *ngIf="course.discount > 0"
                                >{{ course.price_unit }}
                                {{ course.price }}</span
                            >
                            <span class="new" *ngIf="course.discount == 0"
                                >{{ course.price_unit }}
                                {{ course.price }}</span
                            >
                        </div>
                        <div *ngIf="user.type == 'instructor'">
                            <p>No.Students Enroll: {{ course.no_students }}</p>
                            <p>your commssion: {{ course.points }} %</p>
                            <p *ngIf="course.discount == 0">
                                Total Revenue of this Course:$
                                {{ course.no_students * course.price }}
                            </p>
                            <p *ngIf="course.discount > 0">
                                Total Revenue of this Course:$
                                {{
                                    course.no_students * course.discount
                                }}
                            </p>
                            <p *ngIf="course.discount == 0">
                                Your Current Revenue of this Course:$
                                {{
                                    (course.no_students *
                                        course.price *
                                        course.points) /
                                        100
                                }}
                            </p>
                            <p *ngIf="course.discount > 0">
                                Your Current Revenue of this Course:$
                                {{
                                    (course.no_students *
                                        course.discount *
                                        course.points) /
                                        100
                                }}
                            </p>
                        </div>

                        <div class="rating" *ngIf="user.type == 'student'">
                            <i
                                (click)="changeStart(course, 1)"
                                style="font-size: 25px; cursor: pointer"
                                [class]="
                                    course.rate_1 == 1 ||
                                    course.rate_2 == 1 ||
                                    course.rate_3 == 1 ||
                                    course.rate_4 == 1 ||
                                    course.rate_5 == 1
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                (click)="changeStart(course, 2)"
                                style="font-size: 25px; cursor: pointer"
                                [class]="
                                    course.rate_2 == 1 ||
                                    course.rate_3 == 1 ||
                                    course.rate_4 == 1 ||
                                    course.rate_5 == 1
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                (click)="changeStart(course, 3)"
                                style="font-size: 25px; cursor: pointer"
                                [class]="
                                    course.rate_3 == 1 ||
                                    course.rate_4 == 1 ||
                                    course.rate_5 == 1
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                (click)="changeStart(course, 4)"
                                style="font-size: 25px; cursor: pointer"
                                [class]="
                                    course.rate_4 == 1 || course.rate_5 == 1
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                (click)="changeStart(course, 5)"
                                style="font-size: 25px; cursor: pointer"
                                [class]="
                                    course.rate_5 == 1
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a
                        class="prev page-numbers"
                        class="{{ offset <= 0 ? 'disable' : 'enable' }}"
                        (click)="offset = offset - 1; changeOffset()"
                        ><i class="fas fa-angle-double-left"></i
                    ></a>
                            <a aria-current="page" class="page-numbers {{ i == offset ? 'current' : '' }}" *ngFor="let num of number; let i = index" (click)="offset = i; changeOffset()">{{ i + 1 }}</a
                    >
                    <a
                        class="next page-numbers"
                        class="{{
                            offset >= total_pages - 1 ? 'disable' : 'enable'
                        }}"
                        (click)="offset = offset + 1; changeOffset()"
                        ><i class="fas fa-angle-double-right"></i
                    ></a>
                    </div>
                </div>
            </div>
        </div>
</section>