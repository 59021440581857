import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";

@Injectable({
    providedIn: "root",
})
export class LessonService {
    constructor(private _http: HttpClient) {}

    getAllLessons(id?: any) {
        //console.log(id);
        return this._http.post<any>(
            environment.BaseUrl + `/lesson/getAllLessons`,
            { id }
        );
    }
}
