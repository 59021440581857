import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private _http : HttpClient) { }


  createOne(with_coupon?:any,coupon_id?:any,no_item?:any,student_id?:any,total?:any,email?:any,name?:any){
    return this._http.post<any>(environment.BaseUrl+'/order/createOrder/',{with_coupon,coupon_id,no_item,student_id,total,email,name});
  }
}
