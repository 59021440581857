import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "../services/cookie.service";
import { User } from "./auth.models";
import { environment } from 'src/environments/environment.prod';
import { CartService } from "./cart.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    users: any;
    public isAuthorized: boolean = false;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private _cart: CartService,
    ) {
        
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.users) {
            if (this.cookieService.getCookie("currentUser")) {
                this.users = JSON.parse(
                    this.cookieService
                        .getCookie("currentUser")
                        .toString()
                );
                this.isAuthorized = true;
            }
        } else {
            this.isAuthorized = true;
        }

        return this.users;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(user) {
        this.users = user;
        this.cookieService.setCookie(
            "currentUser",
            JSON.stringify(user),
            1
        );
      
        this.isAuthorized = true;
    }

    chechNotifyNumber(){
        this.http.get<any>(environment.BaseUrl+`/notifications`).subscribe(
            (res)=>{
                console.log("Notification",res)
                this._cart.addToNotify(res.data)
            }
        ); 
    }

   NotifyNumber(){
        this.http.get<any>(environment.BaseUrl+`/notifications/count`).subscribe(
            (res)=>{
                console.log("Notification",res)
                this._cart.setUnSeen(res.data)
            }
        ); 
    }
    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie("currentUser");
        this.isAuthorized = false;

        // this.user = null;
    }
}
