import { Component, OnInit } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-cart",
    templateUrl: "./cart.component.html",
    styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
    lang = "en";
    cart = [];
    total: any = 0;
    baseUrl: string = environment.BaseUrl;
    Home: any;
    Cart: any;
    Product: any;
    Name: any;
    Price: any;
    Discount: any;
    AfterDiscount: any;
    ApplyCoupon: any;
    CartTotals: any;
    Total: any;
    ProceedtoCheckout: any;
    Action: any;
    constructor(
        private getCart: CartService,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();

        this.getData();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Cart",
                "Product",
                "Name",
                "Price",
                "Action",
                "Discount",
                "AfterDiscount",
                "ApplyCoupon",
                "CartTotals",
                "Total",
                "ProceedtoCheckout",
            ])

            .subscribe((res) => {
                this.Home = res.Home;
                this.Cart = res.Cart;
                this.Product = res.Product;
                this.Name = res.Name;
                this.Price = res.Price;
                this.Action = res.Action;
                this.Discount = res.Discount;
                this.AfterDiscount = res.AfterDiscount;
                this.ApplyCoupon = res.ApplyCoupon;
                this.CartTotals = res.CartTotals;
                this.Total = res.Total;
                this.ProceedtoCheckout = res.ProceedtoCheckout;
            });
    }

    remove(item) {
        this.getCart.removeItem(item);
        this.getData();
    }

    getData() {
        this.getCart.loadCart();
        this.cart = this.getCart.getItems();
        this.total = this.getTotal();
    }
    getTotal() {
        var total = 0;
        this.cart.forEach((e) => {
            var price= e.price

        if(e.discount>0){
          price= e.discount
        }

            total = Number(total) + Number(price);
        });
        return total.toFixed(2);
    }
}
