import { Component, OnInit } from "@angular/core";
import { DefaultService } from "src/app/services/default.service";
import { PageService } from "src/app/services/page.service";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    term: any;
    priv: any;
    constructor(private _defult: DefaultService, private _page: PageService) {}
    lang: any = "en";
    ngOnInit(): void {
        this.getData();
        if (this._defult.getDefult().langId == 1) this.lang = "en";
        if (this._defult.getDefult().langId == 2) this.lang = "ar";
    }
    posts: any;
    desc: any;
    qk1: any;
    qk2: any;
    qk3: any;
    qk4: any;
    mail: any;
    phone: any;
    address: any;
    getData() {
        {
            this._page
                .getPages("footer", this._defult.getDefult().langId)
                .subscribe(
                    (res) => {
                        //console.log(res);
                        if (res.error == 0) {
                            this.posts = JSON.parse(res.data.Keys);

                            this.desc = this.posts.find(
                                (e) => e.id == "desc"
                            ).value;
                            this.mail = this.posts.find(
                                (e) => e.id == "mail"
                            ).value;
                            this.phone = this.posts.find(
                                (e) => e.id == "phone"
                            ).value;
                            this.term = this.posts.find(
                                (e) => e.id == "term"
                            ).value;
                            this.priv = this.posts.find(
                                (e) => e.id == "priv"
                            ).value;
                            this.address = this.posts.find(
                                (e) => e.id == "address"
                            ).value;
                            this.qk1 = this.posts.find(
                                (e) => e.id == "quick1"
                            ).value;
                            this.qk2 = this.posts.find(
                                (e) => e.id == "quick2"
                            ).value;
                            this.qk3 = this.posts.find(
                                (e) => e.id == "quick3"
                            ).value;
                            this.qk4 = this.posts.find(
                                (e) => e.id == "quick4"
                            ).value;
                        }
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
        }
    }
}
