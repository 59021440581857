<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ CourseDetails }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ CourseDetails }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-details-area ptb-100">
    <div class="video-section pb-100">
        <div class="container">
            <div class="video-content">
                <a href="{{ course.previewvideo }}" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
            </div>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <img src="{{ baseUrl + '/' + course.image }}" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3>{{ course[lang + "_name"] }}</h3>

                    <div class="price">
                        <span class="new" *ngIf="course.discount > 0">{{ course.price_unit }}
                            {{
                               course.discount
                            }}</span>
                        <span class="old" style="
                                text-decoration: line-through;
                                color: grey;
                                margin-left: 10px;
                            " *ngIf="course.discount > 0">{{ course.price_unit }} {{ course.price }}</span>
                        <span class="new" *ngIf="course.discount == 0">{{ course.price_unit }} {{ course.price }}</span>
                    </div>

                    <div class="product-review">
                        <div class="rating">
                            <i [class]="
                                    0 < course.rate && course.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    1 < course.rate && course.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    2 < course.rate && course.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    3 < course.rate && course.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                            <i [class]="
                                    4 < course.rate && course.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "></i>
                        </div>

                        <a class="rating-count">{{ course.sum_rate }}{{ reviews }}</a>
                    </div>

                    <p *ngIf="course[lang + '_des']">
                        {{ course[lang + "_des"].substring(0, 100) }}
                    </p>

                    <div class="product-add-to-cart">
                        <!-- <div class="input-counter">
                            <span class="minus-btn">
                                <i class="fas fa-minus"></i>
                            </span>
                        <input type="text" min="1" value="1">
                        <span class="plus-btn">
                                <i class="fas fa-plus"></i>
                            </span>
                    </div>-->
                        <button *ngIf="course.free == 1" class="default-btn" (click)="enroll(course)">
                            Enroll Now
                        </button>
                        <button *ngIf="course.free == 0 && !isCourseExist(course)" (click)="addToCart(course)" type="submit" class="default-btn">
                            <i class="fas fa-cart-plus"></i> {{ AddToCart }}
                        </button>
                        <button *ngIf="course.free == 0 &&  isCourseExist(course)" (click)="removeFromCart(course)" type="submit" class="default-btn btnhover" style="background-color: #ffffff; color: #fd5a0e">
                            <i class="fas fa-cart-plus"></i>
                            {{ RemovefromCart }}
                        </button>
                        <button *ngIf="course.free == 0" style=" margin-left:20px" (click)="buyNow(course)" type="submit" class="default-btn">
                            {{ BuyNow }}
                        </button>
                    </div>

                    <!--<div class="buy-checkbox-btn">
                        <div class="item">
                            <input class="inp-cbx" id="cbx" type="checkbox">
                            <label class="cbx" for="cbx">
                                <span>
                                    <svg width="12px" height="10px" viewbox="0 0 12 10">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span>I agree with the terms and conditions</span>
                            </label>
                        </div>

                        <div class="item">
                            <a routerLink="/" class="btn btn-light">Buy it now!</a>
                        </div>
                    </div>

                    <div class="custom-payment-options">
                        <span>Guaranteed safe checkout:</span>

                        <div class="payment-methods">
                            <a href="#"><img src="assets/img/payment/1.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/2.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/3.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/4.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/5.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/6.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/7.svg" alt="image"></a>
                        </div>
                    </div>-->
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li>
                            <a style="cursor: pointer">
                                <div class="dot"></div>
                                {{ Description }}
                            </a>
                        </li>
                        <li>
                            <a style="cursor: pointer">
                                <div class="dot"></div>
                                {{ Lessons }}
                            </a>
                        </li>
                        <li>
                            <a style="cursor: pointer">
                                <div class="dot"></div>
                                {{ Reviews }}
                            </a>
                        </li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <p>{{ course[lang + "_des"] }}</p>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="intro_title">
                                    <ul>
                                        <li>
                                            {{ lessons.length }} {{ Lessons }}
                                        </li>
                                        <li>{{ course?.duration }}</li>
                                    </ul>
                                </div>
                                <br />

                                <div id="accordion_lessons" role="tablist" class="add_bottom_45">
                                    <div class="card" *ngFor="let sec of section">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <h5 class="mb-0">
                                                <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <i class="indicator ti-minus"></i> {{ sec[lang + "_name"] }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion_lessons">
                                            <div class="card-body">
                                                <div class="list_lessons">
                                                    <ul>
                                                        <li *ngFor="
                                                                let les of sec.lessons
                                                            ">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="
                                                                                width: 80%;
                                                                            ">
                                                                            <a *ngIf="
                                                                                    les.type ==
                                                                                    0
                                                                                "><i class="fas fa-play"></i>&ensp;{{
                                                                                    les[
                                                                                        lang +
                                                                                            "_name"
                                                                                    ]
                                                                                }}</a>
                                                                            <a *ngIf="
                                                                                    les.type ==
                                                                                    1
                                                                                "><i class="fas fa-file"></i>&ensp;{{
                                                                                    les[
                                                                                        lang +
                                                                                            "_name"
                                                                                    ]
                                                                                }}</a>
                                                                        </td>

                                                                        <td style="
                                                                                width: 20%;
                                                                            ">
                                                                            <span>{{
                                                                                    les.duration
                                                                                }}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- /card -->
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>{{ CustomerReviews }}</h3>

                                    <div class="review-title">
                                        <div class="rating">
                                            <i [class]="
                                                    0 < course.rate &&
                                                    course.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    1 < course.rate &&
                                                    course.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    2 < course.rate &&
                                                    course.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    3 < course.rate &&
                                                    course.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                            <i [class]="
                                                    4 < course.rate &&
                                                    course.rate <= 5
                                                        ? 'fas fa-star'
                                                        : 'far fa-star'
                                                "></i>
                                        </div>
                                        <p>
                                            {{ Basedon }} {{ course.sum_rate }} {{ reviews }}
                                        </p>
                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item" *ngFor="let comment of comments">
                                            <div class="rating">
                                                <i [class]="
                                                        comment.rate_1 == 1 ||
                                                        comment.rate_2 == 1 ||
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_2 == 1 ||
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_3 == 1 ||
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_4 == 1 ||
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                                <i [class]="
                                                        comment.rate_5 == 1
                                                            ? 'fas fa-star'
                                                            : 'far fa-star'
                                                    "></i>
                                            </div>
                                            <h3>
                                                {{ comment.firstName + " " + comment.lastName }}
                                            </h3>
                                            <span>on
                                                <strong>{{
                                                    getDate(comment.createdAt)
                                                }}</strong></span>
                                            <p>{{ comment.Comment }}</p>
                                            <!--<a href="#" class="review-report-link">Report as Inappropriate</a>-->
                                        </div>
                                    </div>

                                    <div class="review-form" *ngIf="
                                            isAuthorized &&
                                            user.type == 'student'
                                        ">
                                        <h3>{{ WriteaReview }}</h3>

                                        <form>
                                            <div class="row">
                                                <!--  <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" id="name" name="name" [placeholder]="[Enteryourname]" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="email" id="email" name="email" [placeholder]="[Enteryouremail]" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <input type="text" id="review-title" name="review-title" [placeholder]="[Enteryourreviewatitle]"  #title class="form-control">
                                                    </div>
                                                </div>-->

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea name="review-body" id="review-body" cols="30" rows="7" [placeholder]="[
                                                                Writeyourcommentshere
                                                            ]" #review class="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="default-btn" (click)="
                                                            submitReview(review)
                                                        ">
                                                        {{ SubmitReview }}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="related-products">
        <div class="container">
            <div class="products-title">
                <span class="sub-title">Our Shop</span>
                <h2>Related Products</h2>
            </div>

            <div class="row text-center alert alert-danger" *ngIf="msg || courses.length == 0">
                {{ msgText }}
            </div>
            <div class="row" *ngIf="courses.length > 0">
                <div class="col-lg-4 col-md-8 col-sm-12 mx-md-auto mx-lg-0" *ngFor="let course2 of courses">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a (click)="goToCourse(course2.id)" style="cursor: pointer" class="d-block"><img src="{{ baseUrl + '/' + course2.image }}" alt="image" /></a>

                            <a *ngIf="course.free == 0 && !isCourseExist(course2)" (click)="addToCart(course2)" class="add-to-cart-btn">Add To Cart
                                <i class="flaticon-shopping-cart"></i></a>
                            <a *ngIf="course.free == 1" (click)="enroll(course)" class="add-to-cart-btn">
                                Enroll Now
                            </a>

                            <a *ngIf="course.free == 0 && isCourseExist(course2)" (click)="removeFromCart(course2)" class="add-to-cart-btn">Remove from Cart<i class="flaticon-shopping-cart"></i></a>








                        </div>

                        <div class="product-content">
                            <h3>
                                <a (click)="goToCourse(course2.id)" style="cursor: pointer">{{ course2[lang + "_name"] }}</a>
                            </h3>

                            <div class="price">
                                <span class="new" *ngIf="course2.discount > 0">{{ course2.price_unit }}
                                    {{course2.discount}}</span>
                                <span class="old" style="
                                        text-decoration: line-through;
                                        color: grey;
                                        margin-left: 10px;
                                    " *ngIf="course2.discount > 0">{{ course2.price_unit }}
                                    {{ course2.price }}</span>
                                <span class="new" *ngIf="course2.discount == 0">{{ course2.price_unit }}
                                    {{ course2.price }}</span>
                            </div>

                            <div class="rating">
                                <i [class]="
                                        0 < course2.rate && course2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        1 < course2.rate && course2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        2 < course2.rate && course2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        3 < course2.rate && course2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                                <i [class]="
                                        4 < course2.rate && course2.rate <= 5
                                            ? 'fas fa-star'
                                            : 'far fa-star'
                                    "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>