<app-navbar-two></app-navbar-two>

<div class="marketing-home-area" style="background-image: url('assets/img/bg.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <span></span>
                            <h1 *ngIf="main != null">
                                {{ main }}
                            </h1>
                            <p *ngIf="main != null">
                                {{ mainDes }}
                            </p>

                            <div class="marketing-btn">
                                <!-- <a routerLink="/courses-list" class="marketing-btn-one">{{ DiscoverMore }}</a> -->
                                <!-- <a
                                    href=""
                                    class="video-btn popup-youtube"
                                    ><span> <i class="fa fa-play"></i></span>
                                    Watch The Demo
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
</div>

<section class="top-services-section pb-100">
    <div class="container">
        <div class="top-services-area">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img class="services" src="assets/img/mop.png" alt="image" />
                        <h3>
                            Single Reservation
                        </h3>
                        <p>
                            Single Reservation Desc
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img class="services" src="assets/img/house-cleaning.png" alt="image" />
                        <h3 >
                           Package Reservations
                        </h3>
                        <p >
                            Package Reservations Desc
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img class="services" src="assets/img/maid.png" alt="image" />
                        <h3 >
                          Contract Reservations
                        </h3>
                        <p >
                            Contract Reservations Desc

                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img class="services" src="assets/img/house.png" alt="image" />
                        <h3 >
                          Offers Reservations
                        </h3>
                        <p >
                            Offers Reservations Desc
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>What We Do?</span>
                    <h3>
                       House Cleaning
                    </h3>
                    <!-- <h3>We develop digital strategies products and services</h3> -->
                    <p >
                        We make sure the safety of your
                        children or family are in a good
                        hands with our Professional Staffs.
                        BABYSITTING AND
                        CAREGIVING ASSITANT
                        Our services will starts AED35/hr
                        but we are requesting for a
                        minimum 4hours per booking.</p>
                    <!-- <p>
                        Moved to a foreign country speaking a different lanuage from yours?
                        <br /> Plan to visit a foreign culture?
                        <br /> Love to learn new languages?
                        <br /> Langely is for you!
                    </p>-

                    <div class="we-btn">
                        <a routerLink="/" class="we-btn-one">Discover More</a>
                    </div>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/mop.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-section pb-70 courses">
    <div class="container">
        <div class="project-title">
            <span>Our Maids</span>
            <h3>
               Our Profesional Maids, Many Nationalities
            </h3>
            <div class="{{ 'project-btn ' + dir }}">
                <a routerLink="/maids" class="project-btn-one">Visit Maids</a>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let course of onApp">
                <div class="single-project-item" [routerLink]="['/single-maid/' + course.id]" >
                    <img [src]=" course?.photo" alt="image" />

                    <div class="project-content">
                        <h3>
                            <a >{{ course.name }}</a>
                        </h3>
                        <div class="price">
                            <span class="new-price">AED
                                {{ course.salary }}</span>
                           
                        </div>
                        <div>
                            {{course?.nationality}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="project-section pb-70 books">
    <div class="container">
        <div class="project-title">
            <span>Our Offers</span>
            <h3>
               Check Our Offers For House Cleaning
            </h3>
            <div class="{{ 'project-btn ' + dir }}">
                <a routerLink="/offers" class="project-btn-one">Visit Offers</a>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let book of offers">
                <div class="single-project-item" >
                    <img [src]=" book?.image" alt="image" />

                    <div class="project-content">
                        <h3>
                            <a>{{
                                book.title
                            }}</a>
                        </h3>
                        <p>
                           $ {{ book.price }}
                        </p>
                        <p>
                            Active to {{book.to | date}}
                        </p>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





    