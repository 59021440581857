import { Component, OnInit } from "@angular/core";
import { BlogService } from "src/app/services/blog.service";
import { BookService } from "src/app/services/book.service";
import { CartService } from "src/app/services/cart.service";
import { CourseService } from "src/app/services/course.service";
import { DefaultService } from "src/app/services/default.service";
import { PostService } from "src/app/services/post.service";
import { ReviewService } from "src/app/services/review.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { PageService } from "src/app/services/page.service";
declare var $: any;
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from "src/app/services/auth.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },

        {
            id: 5,
            value: "rate",
            name: "Sort by average rating",
            type: "DESC",
        },

        {
            id: 6,
            value: "no_student",
            name: "Sort by popularity",
            type: "DESC",
        },
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    courses = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    reviews = [];

    SlideOptions = {
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 2000,
        rtl: true,
        margin: 30,
        center: true,
        active: true,
        autoplayHoverPause: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };

    posts: any;
    aboutUs: any;
    whatWeDo: any;
    clientsSay: any;
    main: any;

    OurCoursesList: any;
    OurBooksList: any;
    OurBlogsList: any;

    feature1: any;
    feature2: any;
    feature3: any;
    feature4: any;

    feature1Des: any;
    feature2Des: any;
    feature3Des: any;
    feature4Des: any;

    feature1Img: any;
    feature2Img: any;
    feature3Img: any;
    feature4Img: any;

    whatWeDoDes: any;
    clientsSayDes: any;
    OurCourse: any;
    OurBooks: any;
    mainDes: any;
    whatWeDoTitle: any;
    whatWeDoImg: any;

    DiscoverMore: any;
    WhatWedo: any;
    OurCourseslist: any;
    VisitCourses: any;
    OurBookslist: any;
    VisitBooks: any;
    OurBlogslist: any;
    VisitBlogs: any;
    OurBlogs: any;

    constructor(
        private _blog: BlogService,
        private _book: BookService,
        private _course: CourseService,
        private _cart: CartService,
        private _defult: DefaultService,
        private _review: ReviewService,
        private _page: PageService,
        public translate: TranslateService,
        private _http : HttpClient,
        private auth :AuthenticationService

    ) {
        this.auth.NotifyNumber()
        this.auth.chechNotifyNumber()
    }
    dir: any = "ltr";

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.dir = "rtl";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.dir = "ltr";
            this.translate.use("en");
        }
        this.getLabels();

        this.getAllOnApp();
      this.getOffers()
    }

    getLabels() {
        this.translate
            .get([
                "DiscoverMore",
                "WhatWedo",
                "OurCourseslist",
                "VisitCourses",
                "OurBookslist",
                "VisitBooks",
                "OurBlogslist",
                "VisitBlogs",
            ])
            .subscribe((res) => {
                this.DiscoverMore = res.DiscoverMore;
                this.WhatWedo = res.WhatWeDo;
                this.OurCourseslist = res.OurCourseslist;
                this.VisitCourses = res.VisitCourses;
                this.OurBookslist = res.OurBookslist;
                this.VisitBooks = res.VisitBooks;
                this.OurBlogslist = res.OurBlogslist;
                this.VisitBlogs = res.VisitBlogs;
            });
    }
    onApp: any = [];
    getAllOnApp() {
      this._http.get<any>(environment.BaseUrl + `/app-maids`).subscribe((res) => {
        console.log(res);
        this.onApp = res.data.reverse().slice(0,2);
     
  
      });
    }
offers:any=[]
    getOffers() {
        this._http.get<any>(environment.BaseUrl + `/offers`).subscribe((res) => {
          console.log(res);
          this.offers = res.data.reverse().slice(0,2);
       
    
        });
      }
    getData() {
        {
            this._page
                .getPages("Home", this._defult.getDefult().langId)
                .subscribe(
                    (res) => {
                        if (res.error == 0) {
                            this.posts = JSON.parse(res.data.Keys);

                            //console.log(res);
                            this.whatWeDoTitle = this.posts.find(
                                (e) => e.id == "whatWeDo_title"
                            ).value;
                            this.whatWeDoImg = this.posts.find(
                                (e) => e.id == "whatWeDoImg"
                            ).value;
                            this.whatWeDoDes = this.posts.find(
                                (e) => e.id == "whatWeDo_des"
                            ).value;
                            this.clientsSay = this.posts.find(
                                (e) => e.id == "ClientsSayTitle"
                            ).value;
                            this.clientsSayDes = this.posts.find(
                                (e) => e.id == "ClientSayDes"
                            ).value;
                            this.OurCourse = this.posts.find(
                                (e) => e.id == "ourCourse"
                            ).value;

                            this.OurCoursesList = this.posts.find(
                                (e) => e.id == "ourCourse_title"
                            ).value;
                            this.OurBooks = this.posts.find(
                                (e) => e.id == "ourBook"
                            ).value;
                            this.OurBooksList = this.posts.find(
                                (e) => e.id == "ourBookTitle"
                            ).value;
                            this.OurBlogsList = this.posts.find(
                                (e) => e.id == "OurBlog"
                            ).value;

                            this.OurBlogs = this.posts.find(
                                (e) => e.id == "OurBlogs"
                            ).value;

                            this.main = this.posts.find(
                                (e) => e.id == "main"
                            ).value;

                            this.mainDes = this.posts.find(
                                (e) => e.id == "mainDes"
                            ).value;

                            this.feature1 = this.posts.find(
                                (e) => e.id == "features1_title"
                            ).value;
                            this.feature2 = this.posts.find(
                                (e) => e.id == "features2_title"
                            ).value;
                            this.feature3 = this.posts.find(
                                (e) => e.id == "features3_title"
                            ).value;
                            this.feature4 = this.posts.find(
                                (e) => e.id == "features4_title"
                            ).value;

                            this.feature1Img = this.posts.find(
                                (e) => e.id == "feature1_image"
                            ).value;
                            this.feature2Img = this.posts.find(
                                (e) => e.id == "feature2_image"
                            ).value;
                            this.feature3Img = this.posts.find(
                                (e) => e.id == "feature3_image"
                            ).value;
                            this.feature4Img = this.posts.find(
                                (e) => e.id == "feature4_image"
                            ).value;

                            this.feature1Des = this.posts.find(
                                (e) => e.id == "features1_des"
                            ).value;
                            this.feature2Des = this.posts.find(
                                (e) => e.id == "features2_des"
                            ).value;
                            this.feature3Des = this.posts.find(
                                (e) => e.id == "features3_des"
                            ).value;
                            this.feature4Des = this.posts.find(
                                (e) => e.id == "features4_des"
                            ).value;
                            this.DiscoverMore = this.posts.find(
                                (e) => e.id == "DiscoverMore"
                            ).value;

                            this.whatWeDo = this.posts.find(
                                (e) => e.id == "whatWeDo"
                            ).value;

                            this.VisitBooks = this.posts.find(
                                (e) => e.id == "VisitBooks"
                            ).value;

                            this.VisitCourses = this.posts.find(
                                (e) => e.id == "VisitCourses"
                            ).value;
                        }
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
        }
    }

    getCourse(sort?: any, limit?: any, offset?: any) {
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        let per_page = this.limit;
        let page = this.offset;

        if (limit != null) {
            per_page = limit;
        }

        if (offset != null) {
            page = offset;
        }

        this._course
            .getAllCourses(null, "", sortValue, sortType, per_page, page)
            .subscribe(
                (res) => {
                    // to check if more courses exists
                    if (res.apiStatus) {
                        this.courses = res.data;
                        if (this.courses.length == 0) {
                            this.msg = true;
                        } else {
                            var count = res.count;
                            this.total_pages = Math.ceil(count / this.limit);
                            this.number = Array.from(
                                { length: this.total_pages },
                                (v, k) => k + 1
                            );
                        }
                    }
                },
                (err) => {
                    this.msg = true;

                    //console.log(err);
                },
                () => {
                    //console.log("sucess");
                }
            );
    }
    books = [];
    getBooks(sort?: any, limit?: any, offset?: any) {
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        let per_page = this.limit;
        let page = this.offset;

        if (limit != null) {
            per_page = limit;
        }

        if (offset != null) {
            page = offset;
        }

        this._book
            .getAllBooks(null, "", sortValue, sortType, per_page, page)
            .subscribe(
                (res) => {
                    //  console.log(res)
                    // to check if more books exists
                    if (res.apiStatus) {
                        this.books = res.data;
                        if (this.books.length == 0) {
                            this.msg = true;
                        } else {
                            var count = res.count;
                            this.total_pages = Math.ceil(count / this.limit);
                            this.number = Array.from(
                                { length: this.total_pages },
                                (v, k) => k + 1
                            );
                        }
                    }
                },
                (err) => {
                    this.msg = true;
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }

    blogs: any = [];
    getBlogs() {
        this._blog.getAllBlogs(3, this.offset).subscribe(
            (res) => {
                if (res.apiStatus) {
                    this.blogs = res.data;
                    var count = res.count;
                    this.total_pages = Math.ceil(count / 3);
                    this.number = Array.from(
                        { length: this.total_pages },
                        (v, k) => k + 1
                    );
                }
            },
            (err) => {}
        );
    }

    getReviews() {
        {
            this._review.getAllReviews().subscribe(
                (res) => {
                    if (res.apiStatus) {
                        this.reviews = res.data;
                        // console.log(this.reviews);
                        this.number = Array.from(
                            { length: this.reviews.length },
                            (v, k) => k + 1
                        );
                    }
                },
                (err) => {
                    // console.log(err);
                }
            );
        }
    }

    //check if item exists
    isCourseExist(course) {
        if (this._cart.itemInCart(course)) {
            return true;
        } else {
            return false;
        }
    }

    //add to cart
    addToCart(course) {
        if (!this._cart.itemInCart(course)) {
            this._cart.addToCart(course); //add items in cart
        }
    }

    // remove course from cart
    removeFromCart(course) {
        if (this._cart.itemInCart(course)) {
            this._cart.removeItem(course); //add items in cart
        }
    }
}
