<!--<div class="c">
    <div class="parent-canvas">

        <span [class]="type=='small'?'student smalls':'student bigs'">{{name}}</span>
        <span [class]="type=='small'?'title smallt':'title bigt'">{{title}}</span>
        <span [class]="type=='small'?'instructor smallv':'instructor bigv'">{{instructor}}</span>
        <span [class]="type=='small'?'valid smallv':'valid bigv'">{{valid}}</span>
        <span [class]="type=='small'?'number smallv':'number bigv'">{{number}}</span>




        <div class="image-canvas">
            <div class="imageupload">
                <img [src]="certificate">
            </div>
        </div>
    </div>

</div>-->

<canvas id="canvas3"></canvas>