import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private http: HttpClient) {}

  getPages(namePage: string, langId?: number) {


      return this.http.post<any>(environment.BaseUrl + "/page/GetNamePage", {
          namePage: namePage,
          langId :langId
      });
  }
}
