import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { TranslateService } from "@ngx-translate/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment.prod";

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit {
  baseUrl: string = environment.BaseUrl;

  constructor( private _http : HttpClient,    private router: Router,
    ) { }

  ngOnInit(): void {
  }
  resend(){
    this._http.post<any>(environment.BaseUrl + `/resend-otp`,{}).subscribe((res) => {
      console.log(res);
  

    });
  }
  confirm(code){
    console.log(code)
    this._http.post<any>(environment.BaseUrl + `/valid-otp`,{
      otp:code.value
    }).subscribe((res) => {
      console.log(res);
      this.router.navigateByUrl("/");
    });
  }

}
